import { useMutation, useQuery } from '@tanstack/react-query';
import { ExternalResponse, IAuth, ILogInRes, ILoginReq } from '_/types';
import { fetchApi, generateUrl, postApi } from './basicApi';

const LOGIN_STATE_KEY = 'bitcash/LOGIN_KEY';

export const useApiLoginState = () => {
  const url = generateUrl(`/v1/auth/signin`);
  const postLogin = (
    loginBody: ILoginReq,
  ): Promise<ExternalResponse<ILogInRes>> => postApi(url, loginBody);

  return useMutation({ mutationFn: postLogin });
};

export const useApiAuth = (pathname: string) => {
  const url = generateUrl(`/v1/auth/isLogin`);
  const enabled =
    pathname !== '/' && pathname !== '/sign-up' && pathname !== '/account-find';
  const fetchAuth = (): Promise<ExternalResponse<IAuth>> => fetchApi(url);

  return useQuery({
    queryKey: [LOGIN_STATE_KEY],
    queryFn: fetchAuth,
    staleTime: Infinity,
    enabled,
    retry: false,
  });
};
