import React, { Dispatch, SetStateAction, useEffect } from 'react';
import Cookies from 'js-cookie';
import { Link } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { GlobalPortal } from '_/GlobalPortal';
import { Close } from '_/assets';
import { ACCESS_TOKEN } from '_/constants';
import { colors } from '_/constants';
import {
  useApiCheckAgreeSignature,
  useLockBodyScroll,
  usePopup,
} from '_/hooks';
import { userState } from '_/recoil';

interface IHeaderMenu {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const HeaderMenu = ({ isOpen, setIsOpen }: IHeaderMenu) => {
  const userInfo = useRecoilValue(userState);
  const { data: isSignatured } = useApiCheckAgreeSignature(userInfo._id);

  const { openPopup } = usePopup();
  const [, setLocked] = useLockBodyScroll();

  useEffect(() => {
    if (!isOpen) {
      setLocked(false);
      return;
    }

    setLocked(true);
  }, [isOpen]);

  const handleClickSignature = () => {
    openPopup('전자 계약이 완료되었습니다.');
  };

  const handleClickClose = () => {
    setIsOpen(!isOpen);
  };

  const handleClickLogOut = () => {
    Cookies.remove(ACCESS_TOKEN);
    window.location.href = '/';
  };

  if (!isOpen) return null;
  return (
    <GlobalPortal.Consumer>
      <StyledWrap>
        <StyledBtnWrap>
          <StyledButton onClick={handleClickClose}>
            <Close />
          </StyledButton>
        </StyledBtnWrap>
        <StyledMenuWrap>
          <StyledMenuLink to={'/points'}>지갑</StyledMenuLink>
          <StyledMenuLink to={'/lock-up-list'}>락업</StyledMenuLink>
          {isSignatured?.data ? (
            <StyledMenuText onClick={handleClickSignature}>
              전자 계약
            </StyledMenuText>
          ) : (
            <StyledMenuLink to={'/signature'}>전자 계약</StyledMenuLink>
          )}
          <StyledMenuLink to={'/withdraw-list'}>출금 내역 확인</StyledMenuLink>
          <StyledMenuLink to={'/deposit-list'}>입금 내역 확인</StyledMenuLink>
          <StyledMenuText onClick={handleClickLogOut}>로그아웃</StyledMenuText>
        </StyledMenuWrap>
      </StyledWrap>
      <StyledDim onClick={handleClickClose} />
    </GlobalPortal.Consumer>
  );
};

export default HeaderMenu;

const StyledDim = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 20;
  animation: fadeIn 200ms forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const StyledWrap = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 100%;
  max-width: 264px;
  background-color: ${colors.white};
  z-index: 30;
  animation: fadeInRight 200ms forwards;

  @keyframes fadeInRight {
    from {
      transform: translate(20px, 0);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`;

const StyledBtnWrap = styled.div`
  text-align: right;
  margin: 9px 16px 0 0;
`;

const StyledButton = styled.button`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
`;

const StyledMenuWrap = styled.div`
  padding: 17px 0 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

const StyledMenuLink = styled(Link)`
  width: fit-content;
  color: ${colors.grey12};
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.32px;
`;

const StyledMenuText = styled.p`
  width: fit-content;
  color: ${colors.grey12};
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.32px;
  cursor: pointer;
`;
