import React from 'react';
import { useCallback } from 'react';

type ClipboardFn = (text: string) => Promise<boolean>;

const useClipboard = (): ClipboardFn => {
  const clipboard: ClipboardFn = useCallback((text: string) => {
    return new Promise((resolve, reject) => {
      copy(text)
        .then((value) => resolve(value))
        .catch(() => reject(false));
    });
  }, []);

  const copy: ClipboardFn = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      return true;
    } catch (error) {
      return false;
    }
  };

  return clipboard;
};

export default useClipboard;
