import React, { useEffect } from 'react';
import styled from 'styled-components';
import { GlobalPortal } from '_/GlobalPortal';
import { colors } from '_/constants';
import { useLoading, useLockBodyScroll } from '_/hooks';

const Loading = () => {
  const [, setLocked] = useLockBodyScroll();
  const { loading } = useLoading();
  const { isOpen } = loading;

  useEffect(() => {
    if (!isOpen) {
      setLocked(false);
      return;
    }

    setLocked(true);
  }, [isOpen]);

  if (!isOpen) return null;
  return (
    <GlobalPortal.Consumer>
      <StyledPosition>
        <StyledWrap>
          <StyledInnerWrap />
          <StyledInnerWrap />
          <StyledInnerWrap />
          <StyledInnerWrap />
        </StyledWrap>
      </StyledPosition>
    </GlobalPortal.Consumer>
  );
};

export default Loading;

const StyledPosition = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 38px;
  height: 38px;
`;

const StyledWrap = styled.div`
  position: relative;
`;

const StyledInnerWrap = styled.div`
  position: absolute;
  border: 4.2px solid orange;
  border-radius: 50%;
  border-color: ${colors.main01} transparent transparent transparent;
  width: 30px;
  height: 30px;
  animation: spinning 1s infinite;

  &:nth-child(1) {
    animation-delay: -0.3s;
  }
  &:nth-child(2) {
    animation-delay: -0.2s;
  }
  &:nth-child(3) {
    animation-delay: -0.1s;
  }

  @keyframes spinning {
    from {
      transform: rotate(0);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
