import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Card, FixedBottomNav, FixedTopHeader } from '_/components';
import { useApiPoints, useLoading } from '_/hooks';
import { userState } from '_/recoil';
import { ICardItem, IPointList } from '_/types';

const Points = () => {
  const { renderLoading } = useLoading();
  const userInfo = useRecoilValue(userState);
  const { data: pointData, isSuccess, isLoading } = useApiPoints(userInfo._id);
  const [pointList, setPointList] = useState<IPointList>();

  useEffect(() => {
    renderLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setPointList(pointData.data);
  }, [pointData, isSuccess, isLoading]);

  if (!pointList) return null;
  const { totalAssetsDollar, totalAssetsWon, wallets } = pointList;
  return (
    <>
      <div className="layout">
        <FixedTopHeader
          type="list"
          totalAssetsDollar={totalAssetsDollar}
          totalAssetsWon={totalAssetsWon}
        />
        <StyledWrap>
          <StyledCardWrap>
            {wallets.map((point: ICardItem, idx) => (
              <Card isButton={true} {...point} key={idx} />
            ))}
          </StyledCardWrap>
        </StyledWrap>
      </div>
      <FixedBottomNav />
    </>
  );
};

export default Points;

const StyledWrap = styled.div`
  margin: 205px 0 130px;
`;

const StyledCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
