import React, {
  ComponentPropsWithoutRef,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  forwardRef,
  useId,
} from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { colors } from '_/constants';

interface IInput extends InputHTMLAttributes<HTMLInputElement> {
  placeholderText?: string;
  type: HTMLInputTypeAttribute;
  labelText?: string;
  warningText?: string;
  isRequired?: boolean;
  isOptional?: boolean;
  isDescription?: boolean;
  buttonProps?: ComponentPropsWithoutRef<typeof Button>;
}

const Input = forwardRef<HTMLInputElement, IInput>(function Input(
  {
    placeholderText,
    type,
    labelText,
    warningText,
    isRequired,
    isOptional,
    isDescription,
    buttonProps,
    ...props
  },
  ref,
) {
  const id = useId();
  return (
    <StyledWrap>
      {labelText && (
        <StyledLabel htmlFor={id}>
          {labelText}
          {isRequired && <span className="required">*</span>}
          {isOptional && <span className="optional">(선택)</span>}
        </StyledLabel>
      )}
      <StyledInputWrap>
        <StyledInput
          placeholder={placeholderText}
          type={type}
          id={id}
          ref={ref}
          onWheel={(e) => (e.target as HTMLInputElement).blur()}
          {...props}
        />
        {buttonProps && (
          <StyledButtonWrap>
            <Button {...buttonProps} />
          </StyledButtonWrap>
        )}
        {isDescription && (
          <StyledDescriptionWrap>
            <StyledDescription>KRW</StyledDescription>
          </StyledDescriptionWrap>
        )}
      </StyledInputWrap>

      {warningText && <StyledWarningText>{warningText}</StyledWarningText>}
    </StyledWrap>
  );
});

export default Input;

const StyledWrap = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const StyledLabel = styled.label`
  color: ${colors.grey07};
  margin-left: 4px;
  font-size: 14px;

  span {
    margin-left: 2px;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.3px;
  }

  span[class='required'] {
    color: ${colors.red};
  }

  span[class='optional'] {
    color: ${colors.main02};
  }
`;

const StyledInputWrap = styled.div`
  display: flex;
  gap: 7px;

  input::placeholder {
    color: #c8c8c8;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.32px;
  }

  input:-ms-input-placeholder {
    color: #c8c8c8;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: -0.32px;
  }

  input:focus {
    border: 1px solid ${colors.main02};
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    opacity: 1;
    margin: 0;
  }
`;

const StyledInput = styled.input`
  box-sizing: border-box;
  min-width: 124px;
  width: 100%;
  border: 1px solid ${colors.grey02};
  border-radius: 14px;
  padding: 14px 12px;
  font-size: 15px;
`;

const StyledButtonWrap = styled.div`
  width: 103px;
`;

const StyledDescriptionWrap = styled.div`
  width: 100%;
  min-width: 200px;
  display: flex;
  align-items: center;
  margin-left: 1px;
`;

const StyledDescription = styled.p`
  color: ${colors.grey12};
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.32px;
`;

const StyledWarningText = styled.p`
  color: ${colors.red};
  font-size: 12px;
  margin-top: 4px;
  margin-left: 4px;
`;
