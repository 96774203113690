import React from 'react';

const Refresh = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
    >
      <path
        d="M12.8334 13.75L9.16675 17.4167L12.8334 21.0833"
        stroke="#8E8E93"
        strokeWidth="1.5"
      />
      <path
        d="M5.44309 14.2083C4.71611 12.9491 4.43831 11.4804 4.65522 10.0427C4.87212 8.60498 5.57086 7.28358 6.63697 6.29494C7.70308 5.30629 9.07334 4.70903 10.5233 4.60099C11.9732 4.49295 13.4169 4.88054 14.6177 5.70026C15.8186 6.51999 16.7054 7.72324 17.133 9.11291C17.5606 10.5026 17.5035 11.9963 16.9712 13.3493C16.4389 14.7023 15.4628 15.8344 14.2029 16.5601C12.943 17.2858 11.474 17.5622 10.0366 17.3438"
        stroke="#8E8E93"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default Refresh;
