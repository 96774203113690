import React from 'react';

const Line_grey = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="3"
      height="14"
      viewBox="0 0 3 14"
      fill="none"
    >
      <path d="M1.4707 0L1.4707 14" stroke="#8E8E93" strokeWidth="2" />
    </svg>
  );
};

export default Line_grey;
