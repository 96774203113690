import { atom } from 'recoil';
import { IAuth } from '_/types';

export const userState = atom<IAuth>({
  key: 'userState',
  default: {
    _id: null,
    name: '',
  },
});
