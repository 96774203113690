import { useQuery } from '@tanstack/react-query';
import { ExternalResponse, IWithdrawItem, UserIdType } from '_/types';
import { fetchApi, generateUrl } from './basicApi';

const WITHDRAW_LIST_STATE_KEY = 'bitcash/WITHDRAW_LIST';

export const useApiWithdrawList = (userId: UserIdType) => {
  const url = generateUrl(`/v1/users/${userId}/withdraw`);

  const fetchWithdrawList = (): Promise<ExternalResponse<IWithdrawItem[]>> =>
    fetchApi(url);

  return useQuery({
    queryKey: [WITHDRAW_LIST_STATE_KEY, userId],
    queryFn: fetchWithdrawList,
    // staleTime: Infinity,
    enabled: !!userId,
    retry: 3,
  });
};
