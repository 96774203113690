import React from 'react';

const Back = () => {
  return (
    <svg
      width="11"
      height="20"
      viewBox="0 0 11 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.2759 18.5523L1.72363 10L10.2759 1.44775"
        stroke="#040216"
        strokeWidth="1.5"
      />
    </svg>
  );
};

export default Back;
