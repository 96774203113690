export const convertDate = (date: string) => {
  const TIME_ZONE = 9 * 60 * 60 * 1000; // 9시간

  const curDate = new Date(date);
  return new Date(curDate.getTime() + TIME_ZONE)
    .toISOString()
    .replace('T', ' ')
    .slice(0, -5);
};

export const addComma = (num: string | number) => {
  if (num === undefined || num === null) return '';

  const regexp = /\B(?=(\d{3})+(?!\d))/g;
  return num.toString().replace(regexp, ',');
};
