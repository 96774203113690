import React, { Dispatch, SetStateAction, useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { GlobalPortal } from '_/GlobalPortal';
import { Close, Link } from '_/assets';
import { colors } from '_/constants';
import { useClipboard, useLockBodyScroll } from '_/hooks';
import { IDepositAddress } from '_/types';

interface IFixedBottomPopup {
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  address?: IDepositAddress;
}

const FixedBottomPopup = ({
  isOpen,
  setIsOpen,
  address,
}: IFixedBottomPopup) => {
  const [, setLocked] = useLockBodyScroll();
  const clipboard = useClipboard();

  useEffect(() => {
    if (!isOpen) {
      setLocked(false);
      return;
    }

    setLocked(true);
  }, [isOpen]);

  const handleClickClose = () => {
    setIsOpen(false);
  };

  const handleCopyWalletAddress = async (address: string) => {
    try {
      const res = await clipboard(address);
      if (!res) throw Error('copy failed');
      console.log(res);
    } catch (e) {
      throw Error('copy failed');
    }
  };

  if (!isOpen || !address) return null;
  const { walletAddress, accountAddress } = address;
  return (
    <GlobalPortal.Consumer>
      <StyledDim onClick={handleClickClose} />
      <StyledWrap>
        <StyledInner>
          <StyledBtnWrap>
            <StyledButton onClick={handleClickClose}>
              <Close />
            </StyledButton>
          </StyledBtnWrap>
          <StyledTextWrap>
            <StyledHeadLine>입금 전 확인해주세요!</StyledHeadLine>
            <div>
              <StyledPlaneText>
                1. 입금시 꼭 지갑 주소를 다시 한번 확인 바랍니다.
              </StyledPlaneText>
              <StyledPlaneText>
                2. 잘못된 주소로 입금시 해당 자산은 찾을 수 없습니다.
              </StyledPlaneText>
            </div>
          </StyledTextWrap>
          <StyledWalletWrap>
            <StyledWalletAddressWrap>
              <StyledAddressWrap>
                <StyledWalletTitle>지갑 주소</StyledWalletTitle>
                <StyledWalletAddress>{walletAddress}</StyledWalletAddress>
              </StyledAddressWrap>
              <StyledLine />
              <StyledCopyButton
                onClick={() => handleCopyWalletAddress(walletAddress)}
              >
                <Link />
                <span>복사하기</span>
              </StyledCopyButton>
            </StyledWalletAddressWrap>
            {accountAddress && (
              <StyledWalletAddressWrap>
                <StyledAddressWrap>
                  <StyledWalletTitle>계좌 번호</StyledWalletTitle>
                  <StyledWalletAddress>{accountAddress}</StyledWalletAddress>
                </StyledAddressWrap>
                <StyledLine />
                <StyledCopyButton
                  onClick={() => handleCopyWalletAddress(accountAddress)}
                >
                  <Link />
                  <span>복사하기</span>
                </StyledCopyButton>
              </StyledWalletAddressWrap>
            )}
          </StyledWalletWrap>
        </StyledInner>
      </StyledWrap>
    </GlobalPortal.Consumer>
  );
};

export default FixedBottomPopup;

const StyledDim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 50;
  animation: fadeIn 200ms forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const StyledWrap = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 50;
`;

const StyledInner = styled.div`
  max-width: 575px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  border-radius: 24px 24px 0px 0px;
  background: ${colors.white};
  animation: fadeInBottom 200ms forwards;
  padding-bottom: 42px;
  box-sizing: border-box;

  @keyframes fadeInBottom {
    from {
      transform: translate(0, 20px);
      opacity: 0;
    }
    to {
      transform: translate(0, 0);
      opacity: 1;
    }
  }
`;

const StyledBtnWrap = styled.div`
  text-align: right;
  margin: 16px 16px 0 0;
`;

const StyledButton = styled.button`
  width: fit-content;
  height: fit-content;
  cursor: pointer;
`;

const StyledTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin: 0 20px;
`;

const StyledHeadLine = styled.h1`
  color: ${colors.grey12};
  font-size: 20px;
  font-weight: 700;
  line-height: 25px;
  letter-spacing: 0.38px;
`;

const StyledPlaneText = styled.p`
  color: ${colors.grey09};
  font-size: 14px;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.24px;
`;

const StyledWalletWrap = styled.div`
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledWalletAddressWrap = styled.div`
  border-radius: 18px;
  background: var(--gray_00, #f5f5f8);
  padding: 18px 25px 10px;
  box-sizing: border-box;
`;

const StyledAddressWrap = styled.div`
  display: inline-flex;
  flex-direction: column;
  gap: 5px;
`;

const StyledLine = styled.hr`
  background: ${colors.grey02};
  height: 1.5px;
  border: 0;
  margin: 13px 0;
`;

const StyledWalletTitle = styled.h2`
  color: ${colors.main02};
  font-size: 14px;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: -0.078px;
`;

const StyledWalletAddress = styled.p`
  color: ${colors.grey08};
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.078px;
  word-break: break-all;
`;

const StyledCopyButton = styled.button`
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 2px;

  span {
    color: ${colors.grey10};
    font-size: 13px;
    font-weight: 600;
    line-height: 17px;
  }
`;
