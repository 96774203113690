import React, { useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { addComma } from 'src/utils';
import styled from 'styled-components';
import { Button, Card, FixedTopHeader, Input } from '_/components';
import { colors, WithdrawValidation } from '_/constants';
import {
  usePopup,
  useApiWithdrawable,
  useApiWithdrawReq,
  useLoading,
} from '_/hooks';
import { userState } from '_/recoil';
import { IMutateStatus, IPossibleWithdraw, IWithdrawReq } from '_/types';

const Withdraw = () => {
  const userInfo = useRecoilValue(userState);
  const navigate = useNavigate();
  const { ID: walletId } = useParams();
  const { openPopup } = usePopup();
  const { renderLoading } = useLoading();

  const { register, handleSubmit, formState, watch } = useForm<IWithdrawReq>();
  const { errors } = formState;

  const [withdrawable, setWithdrawable] = useState<IPossibleWithdraw>();
  const [isFillAllInput, setIsFillAllInput] = useState<boolean>(false);
  const [isPossibleSubmit, setIsPossibleSubmit] = useState<boolean>(false);
  const [totalAsset, setTotalAsset] = useState<string>('');

  const {
    data: withDrawable,
    isSuccess: isSuccessWithdrawable,
    isLoading: isLoadingWithdrawable,
  } = useApiWithdrawable(userInfo._id, walletId);
  const {
    mutate: mutateWithdraw,
    status: withdrawStatus,
    isPending: isPendingWithdraw,
  } = useApiWithdrawReq();

  const MAX_AMOUNT = useMemo(() => {
    if (!withdrawable) return '0';
    return withdrawable.withdrawableWon.split('<')[0].replaceAll(',', '');
  }, [withdrawable]);

  useEffect(() => {
    renderLoading(isLoadingWithdrawable || isPendingWithdraw);
  }, [isLoadingWithdrawable, isPendingWithdraw]);

  useEffect(() => {
    if (!isSuccessWithdrawable) {
      return;
    }

    setWithdrawable(withDrawable.data);
  }, [withDrawable, isSuccessWithdrawable]);

  useEffect(() => {
    const subscription = watch((inputObj) => {
      let isFill = true;
      const inputArr = Object.values(inputObj) as Array<string>;

      // Empty value check
      if (inputArr.includes('')) {
        isFill = false;
      }

      setIsFillAllInput(isFill);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setIsPossibleSubmit(
      isFillAllInput &&
        (!formState.errors || !Object.keys(formState.errors).length),
    );
  }, [formState, isFillAllInput]);

  useEffect(() => {
    handleWithdrawState(withdrawStatus);
  }, [withdrawStatus]);

  const handleSubmitBtn: SubmitHandler<IWithdrawReq> = async (data) => {
    if (Number(MAX_AMOUNT) < data.amount) {
      openPopup('출금 가능 금액보다 적은 출금 금액을 입력해주세요.');
      return;
    }

    mutateWithdraw({ userId: userInfo._id, walletId, withdrawBody: data });
  };

  const handleWithdrawState = (status: IMutateStatus) => {
    switch (status) {
      case 'success':
        openPopup('출금이 완료되었습니다!', () => navigate('/withdraw-list'));
        return;
      case 'error':
        openPopup('출금 오류가 발생했습니다');
        return;
    }
  };

  if (!withdrawable) return null;
  const { wallet, withdrawableWon } = withdrawable;
  return (
    <>
      <div className="layout">
        <FixedTopHeader type="card">
          <Card {...wallet} />
        </FixedTopHeader>
        <StyledWrap>
          <StyledInnerForm onSubmit={handleSubmit(handleSubmitBtn)}>
            <StyledTotalWrap>
              <StyledHeadLine>출금 가능 금액</StyledHeadLine>
              <AssetsWrap>
                <StyledDollar
                  dangerouslySetInnerHTML={{
                    __html: withdrawableWon,
                  }}
                />
              </AssetsWrap>
            </StyledTotalWrap>
            <StyledInputWrap>
              <Input
                placeholderText="0"
                type="number"
                labelText="출금 금액"
                isRequired={true}
                isDescription={true}
                {...register('amount', {
                  required: '출금 금액은 필수 입력입니다.',
                  valueAsNumber: true,
                })}
                warningText={errors.amount && errors.amount.message}
                onChange={(e) => setTotalAsset(e.currentTarget.value)}
              />
              <Input
                placeholderText="은행 입력"
                type="text"
                labelText="은행 입력"
                isRequired={true}
                {...register('bankName', {
                  required: '은행은 필수 입력입니다.',
                })}
                warningText={errors.bankName && errors.bankName.message}
              />
              <Input
                placeholderText="보내실 계좌번호 입력"
                type="text"
                labelText="계좌번호 입력"
                isRequired={true}
                {...register('accountNumber', {
                  required: '계좌번호는 필수 입력입니다.',
                  pattern: WithdrawValidation.accountNumber,
                })}
                warningText={
                  errors.accountNumber && errors.accountNumber.message
                }
              />
              <Input
                placeholderText="지갑 비밀번호 입력"
                type="password"
                labelText="지갑 비밀번호"
                isRequired={true}
                {...register('walletPassword', {
                  required: '지갑 비밀번호는 필수 입력입니다.',
                  pattern: WithdrawValidation.walletPassword,
                })}
                warningText={
                  errors.walletPassword && errors.walletPassword.message
                }
              />
            </StyledInputWrap>
            <StyledAssetsWrap>
              <AssetInner>
                {/* <StyledTextWrap>
                  <StyledTitle>출금 요청 수량</StyledTitle>
                  <StyledCashText>
                    0.0000 <StyledSpan>Bitcoin cash</StyledSpan>
                  </StyledCashText>
                </StyledTextWrap>
                <StyledLine /> */}
                <StyledTextWrap>
                  <StyledTitle>총 금액</StyledTitle>
                  <StyledCashText>
                    {totalAsset.length ? addComma(totalAsset) : '0'}
                    <StyledSpan>원</StyledSpan>
                  </StyledCashText>
                </StyledTextWrap>
              </AssetInner>
            </StyledAssetsWrap>
            <Button
              text="보내기"
              type="submit"
              disabled={
                isPendingWithdraw || !isFillAllInput || !isPossibleSubmit
              }
            />
          </StyledInnerForm>
        </StyledWrap>
      </div>
    </>
  );
};

export default Withdraw;

const StyledWrap = styled.div`
  margin: 195px 0 110px;
`;

const StyledInnerForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 36px;
`;

// Input 리스트 영역
const StyledInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

// 최상단 출금 가능 수량
const StyledTotalWrap = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const StyledHeadLine = styled.h2`
  font-size: 15px;
  font-weight: 600;
  line-height: 20px;
  color: ${colors.main02};
`;

const AssetsWrap = styled.div`
  display: flex;
  align-items: center;
`;

const StyledDollar = styled.h1`
  color: ${colors.grey12};
  font-size: 30px;
  font-weight: bold;
  letter-spacing: 0.36px;

  span {
    margin-left: 10px;
    color: ${colors.grey12};
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
  }
`;

// 하단 총 금액 영역
const StyledAssetsWrap = styled.div`
  width: 100%;
  border-radius: 18px;
  background: ${colors.grey00};
  padding: 18px;
  box-sizing: border-box;
`;

const AssetInner = styled.div`
  flex-direction: column;
  gap: 10px;
`;

const StyledTextWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledLine = styled.hr`
  background: ${colors.grey02};
  height: 1.5px;
  border: 0;
`;

const StyledTitle = styled.p`
  color: ${colors.grey08};
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
`;

const StyledCashText = styled.p`
  color: ${colors.grey12};
  font-size: 20px;
  font-weight: 600;
  line-height: 25px;
`;

const StyledSpan = styled.span`
  color: ${colors.grey12};
  text-align: center;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.24px;
  margin-left: 6px;
`;
