import React from 'react';
import styled from 'styled-components';
import { colors } from '_/constants';

interface IText {
  text: string;
}

const Text = ({ text }: IText) => {
  return <StyledTitle>{text}</StyledTitle>;
};

export default Text;

const StyledTitle = styled.h1`
  position: relative;
  margin-bottom: 16px;
  margin-left: 4px;
  color: ${colors.grey12};
  font-size: 18px;
  font-weight: 700;
  line-height: 22px; /* 122.222% */
  letter-spacing: -0.408px;
  z-index: 5;
`;
