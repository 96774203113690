import React, { useEffect, useRef, useState } from 'react';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import SignatureCanvas from 'react-signature-canvas';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Refresh } from '_/assets';
import { Background, Button, CheckBox, Text, Header } from '_/components';
import { colors } from '_/constants';
import {
  useApiAgreeSignature,
  useCheckBoxPopup,
  useLoading,
  usePopup,
} from '_/hooks';
import { userState } from '_/recoil';
import { IMutateStatus } from '_/types';

const Signature = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userState);

  const { openCheckBoxPopup } = useCheckBoxPopup();
  const { openPopup } = usePopup();
  const { renderLoading } = useLoading();

  const [isVerified, setIsVerified] = useState<boolean>(false);
  const [isSigned, setIsSigned] = useState<boolean>(false);
  const canvasRef = useRef<any>(null);

  const { status, mutate, isPending } = useApiAgreeSignature();

  useEffect(() => {
    renderLoading(isPending);
  }, [isPending]);

  useEffect(() => {
    handleSubmitAgree(status);
  }, [status]);

  const convertDataUrlToFile = (name: string) => {
    if (!canvasRef.current) return;

    const dataURL = canvasRef.current.toDataURL('image/jpg');
    const decodedURL = dataURL.replace(/^data:image\/\w+;base64,/, '');
    const buf = Buffer.from(decodedURL, 'base64');
    const blob = new Blob([buf], { type: 'image/jpg' });
    return new File([blob], `${name}.jpg`, { type: 'image/jpg' });
  };

  const handleClickRefresh = () => {
    canvasRef.current.clear();
    setIsSigned(false);
  };

  const handleClickAgreeBtn = () => {
    if (!isSigned) {
      openPopup('전자서명을 해주세요!');
      return;
    }

    if (!isVerified) {
      openPopup('전자서명에 동의해주세요!');
      return;
    }

    const { _id } = userInfo;
    mutate({
      userId: _id,
      file: convertDataUrlToFile(`${_id}`),
    });
  };

  const handleSubmitAgree = (status: IMutateStatus) => {
    switch (status) {
      case 'success':
        openPopup('전자서명이 완료되었습니다', () => navigate('/points'));
        return;
      case 'error':
        openPopup('오류가 발생했습니다');
        return;
    }
  };

  const handleAgreeSignature = () => {
    if (!isVerified) {
      openCheckBoxPopup('signature');
    }

    setIsVerified(!isVerified);
  };

  return (
    <>
      <Header />
      <div className="layout">
        <StyledWrap>
          <Text text="전자서명 이용약관" />
          <StyledCanvasWrap>
            <StyledRefreshButton
              onClick={handleClickRefresh}
              disabled={!isSigned}
            >
              <StyledIconWrap>
                <Refresh />
              </StyledIconWrap>
              <span>서명 초기화</span>
            </StyledRefreshButton>
            {!isSigned && (
              <CanvasPlaceholder>
                <CanvasText>Please enter</CanvasText>
                <CanvasText>your electronic signature</CanvasText>
              </CanvasPlaceholder>
            )}
            <SignatureCanvas
              ref={canvasRef}
              canvasProps={{
                className: 'signature-canvas',
                width: 575,
                height: 390,
              }}
              clearOnResize={false}
              onBegin={() => {
                setIsSigned(true);
              }}
            />
          </StyledCanvasWrap>
          <StyledCheckboxWrap>
            <CheckBox
              text="전자서명에 동의합니다"
              isChecked={isVerified}
              onChange={handleAgreeSignature}
            />
          </StyledCheckboxWrap>
          <StyledButtonWrap>
            <Button text="동의하기" onClick={handleClickAgreeBtn} />
          </StyledButtonWrap>
        </StyledWrap>
      </div>
      <Background />
    </>
  );
};

export default Signature;

const StyledWrap = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
`;

const StyledCanvasWrap = styled.div`
  position: relative;
  width: 100%;
  height: 390px;
  border-radius: 20px;
  border: 1px solid ${colors.grey01};
  background: ${colors.white};
  overflow: hidden;
`;

const StyledRefreshButton = styled.button`
  position: absolute;
  right: 0;
  bottom: 0;
  margin: 0 12px 12px 0;
  padding: 5px 12px 5px 9px;
  box-sizing: border-box;
  width: 106px;
  height: 32px;
  display: flex;
  gap: 2px;
  align-items: center;
  background-color: ${colors.grey00};
  border-radius: 9px;

  span {
    color: ${colors.grey06};
    font-size: 13px;
    font-weight: 600;
    line-height: 20px;
    letter-spacing: -0.5px;
  }
`;

const StyledIconWrap = styled.div`
  width: 22px;
  height: 22px;
`;

const CanvasPlaceholder = styled.div`
  margin-top: 14px;
  text-align: center;
`;

const CanvasText = styled.p`
  color: ${colors.grey06};
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
`;

const StyledCheckboxWrap = styled.div`
  margin-top: 20px;
`;

const StyledButtonWrap = styled.div`
  margin-top: 32px;
`;
