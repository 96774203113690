import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { BitcashHeaderLogo, Menu } from '_/assets';
import { userState } from '_/recoil';
import HeaderMenu from './HeaderMenu';

const Header = () => {
  const navigate = useNavigate();
  const userInfo = useRecoilValue(userState);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);

  const handleClickMenuBtn = () => {
    setIsOpenMenu(!isOpenMenu);
  };

  const handleClickLogo = () => {
    if (userInfo) {
      navigate('/points');
      return;
    }

    navigate('/');
  };

  return (
    <>
      <HeaderMenu isOpen={isOpenMenu} setIsOpen={setIsOpenMenu} />
      <StyledWrap>
        <StyledLogoButton onClick={handleClickLogo}>
          <BitcashHeaderLogo />
        </StyledLogoButton>
        <StyledMenuButton onClick={handleClickMenuBtn}>
          <Menu />
        </StyledMenuButton>
      </StyledWrap>
    </>
  );
};

export default Header;

const StyledWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 6px 16px 7px 20px;
  width: 100%;
  max-width: 575px;
  box-sizing: border-box;
  z-index: 100;
  margin: 0 auto;
`;

const StyledLogoButton = styled.button`
  position: relative;
  z-index: 10;
`;

const StyledMenuButton = styled.button`
  z-index: 10;
  cursor: pointer;
`;
