import { useQuery } from '@tanstack/react-query';
import { ExternalResponse, IDepositAddress } from '_/types';
import { fetchApi, generateUrl } from './basicApi';

const DEPOSIT_WALLET_KEY = 'bitcash/LOCKUP';

export const useApiDepositAddress = () => {
  const url = generateUrl(`/v1/info/wallet-address`);

  const fetchLockUp = (): Promise<ExternalResponse<IDepositAddress>> =>
    fetchApi(url);

  return useQuery({
    queryKey: [DEPOSIT_WALLET_KEY],
    queryFn: fetchLockUp,
    staleTime: Infinity,
    retry: 3,
  });
};
