import React from 'react';

const Menu = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <path d="M4.16656 6.04154H20.114" stroke="#040216" strokeWidth="1.5" />
      <path d="M4 18.5H20" stroke="#040216" strokeWidth="1.5" />
      <path d="M4.16656 12.3396H20.114" stroke="#040216" strokeWidth="1.5" />
    </svg>
  );
};

export default Menu;
