import React, { ButtonHTMLAttributes, ReactNode, forwardRef } from 'react';
import styled from 'styled-components';
import { colors } from '_/constants';

type ColorType = 'main' | 'grey';
type SizeType = 'small' | 'medium' | 'large';

type ReturnSizeType = {
  padding: string;
  borderRadius: string;
  fontSize: string;
  fontWeight: number;
};
type ReturnColorType = {
  color: string;
  bgColor: string;
};

interface IButton extends ButtonHTMLAttributes<HTMLButtonElement> {
  text: string | ReactNode;
  colorType?: ColorType;
  sizeType?: SizeType;
}

const Button = forwardRef<HTMLButtonElement, IButton>(function Button(
  {
    text,
    type,
    colorType = 'main',
    sizeType = 'large',
    disabled,
    onClick,
    ...props
  },
  ref,
) {
  const getButtonSize = (sizeType: SizeType): ReturnSizeType => {
    switch (sizeType) {
      case 'large':
        return {
          padding: '17px 9px',
          borderRadius: '14px',
          fontSize: '15px',
          fontWeight: 700,
        };
      case 'medium':
        return {
          padding: '14px 8px',
          borderRadius: '10px',
          fontSize: '14px',
          fontWeight: 600,
        };
      case 'small':
        return {
          padding: '14px 0',
          borderRadius: '10px',
          fontSize: '15px',
          fontWeight: 600,
        };
    }
  };

  const getButtonColor = (colorType: ColorType): ReturnColorType => {
    switch (colorType) {
      case 'main':
        return {
          color: colors.white,
          bgColor: colors.main02,
        };
      case 'grey':
        return {
          color: colors.grey06,
          bgColor: colors.grey00,
        };
    }
  };

  return (
    <StyledButton
      type={type ? type : 'button'}
      disabled={disabled || false}
      onClick={onClick}
      $colorType={getButtonColor(colorType)}
      $sizeType={getButtonSize(sizeType)}
      ref={ref}
      {...props}
    >
      <TextWrap>{text}</TextWrap>
    </StyledButton>
  );
});

export default Button;

const StyledButton = styled.button<{
  disabled: boolean;
  $colorType?: ReturnColorType;
  $sizeType?: ReturnSizeType;
}>`
  width: 100%;
  padding: ${({ $sizeType }) => $sizeType?.padding};
  color: ${({ $colorType }) => $colorType?.color};
  background-color: ${({ disabled, $colorType }) =>
    disabled ? colors.grey02 : $colorType?.bgColor};
  border-radius: ${({ $sizeType }) => $sizeType?.borderRadius};
  font-size: ${({ $sizeType }) => $sizeType?.fontSize};
  font-weight: ${({ $sizeType }) => $sizeType?.fontWeight};
  line-height: normal;
`;

const TextWrap = styled.div`
  width: 100%;
  text-align: center;
`;
