import { useMutation } from '@tanstack/react-query';
import { ExternalResponse, ISignUpRes, IAccountFindReq } from '_/types';
import { generateUrl, postApi } from './basicApi';

export const useApiAccountFind = () => {
  const url = generateUrl(`/v1/auth/find-id-password`);
  const postAccountFind = (
    accountFindBody: IAccountFindReq,
  ): Promise<ExternalResponse<ISignUpRes>> => postApi(url, accountFindBody);

  return useMutation({ mutationFn: postAccountFind });
};
