import { useMutation } from '@tanstack/react-query';
import { ExternalResponse, IAccountUpdateReq } from '_/types';
import { generateUrl, postApi } from './basicApi';

export const useApiAccountUpdate = () => {
  const url = generateUrl(`/v1/auth/set-password`);
  const postAccountUpdate = (
    accountUpdateBody: IAccountUpdateReq,
  ): Promise<ExternalResponse<any>> => postApi(url, accountUpdateBody);

  return useMutation({ mutationFn: postAccountUpdate });
};
