import React, { InputHTMLAttributes, forwardRef, useId } from 'react';
import styled from 'styled-components';
import { Checked, NotChecked } from '_/assets';
import { colors } from '_/constants';

interface ICheckBox extends InputHTMLAttributes<HTMLInputElement> {
  text: string;
  isChecked: boolean;
}

const CheckBox = forwardRef<HTMLInputElement, ICheckBox>(function Checkbox(
  { text, isChecked, ...props },
  ref,
) {
  const id = useId();

  return (
    <StyledWrap>
      <StyledInput type="checkbox" id={id} ref={ref} {...props} />
      <StyledLabel htmlFor={id}>
        {isChecked ? <Checked /> : <NotChecked />}
      </StyledLabel>
      <StyledText>{text}</StyledText>
    </StyledWrap>
  );
});

export default CheckBox;

const StyledWrap = styled.div`
  height: 28px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

const StyledInput = styled.input`
  display: none;
`;

const StyledLabel = styled.label`
  display: inline-block;
  width: 28px;
  height: 28px;
  position: relative;
  cursor: pointer;
`;

const StyledText = styled.p`
  color: ${colors.grey12};
  font-size: 16px;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: -0.32px;
`;
