import { useQuery } from '@tanstack/react-query';
import { ExternalResponse, IPossibleWithdraw, UserIdType } from '_/types';
import { fetchApi, generateUrl } from './basicApi';

const WITHDRAW_STATE_KEY = 'bitcash/WITHDRAWABLE';

export const useApiWithdrawable = (userId: UserIdType, walletId?: string) => {
  const url = generateUrl(
    `/v1/users/${userId}/wallets/${walletId}/withdrawable`,
  );

  const fetchWithdrawable = (): Promise<ExternalResponse<IPossibleWithdraw>> =>
    fetchApi(url);

  return useQuery({
    queryKey: [WITHDRAW_STATE_KEY, userId, walletId],
    queryFn: fetchWithdrawable,
    enabled: !!userId && !!walletId,
    retry: 3,
  });
};
