import { useMutation } from '@tanstack/react-query';
import { ExternalResponse, IWithdraw, IWithdrawReq, UserIdType } from '_/types';
import { generateUrl, postApi } from './basicApi';

interface IWithDrawMutation {
  userId: UserIdType;
  walletId: string;
  withdrawBody: IWithdrawReq;
}

export const useApiWithdrawReq = () => {
  const postWithdraw = ({
    userId,
    walletId,
    withdrawBody,
  }: IWithDrawMutation): Promise<ExternalResponse<IWithdraw>> => {
    const url = generateUrl(`/v1/users/${userId}/wallets/${walletId}/withdraw`);

    return postApi(url, withdrawBody);
  };

  return useMutation({ mutationFn: postWithdraw });
};
