import React, { ReactNode } from 'react';
import styled from 'styled-components';
import Header from '../Header';
import { GlobalPortal } from '_/GlobalPortal';
import { colors } from '_/constants';
import { ITotalAsset } from '_/types';

type HeaderType = 'list' | 'card';
interface IFixedTopHeader extends ITotalAsset {
  type: HeaderType;
  children?: ReactNode;
}

const FixedTopHeader = function FixedTopHeader({
  type,
  children,
  totalAssetsDollar,
  totalAssetsWon,
}: IFixedTopHeader) {
  const isTotalAsset = !!totalAssetsDollar?.length && !!totalAssetsWon?.length;
  return (
    <GlobalPortal.Consumer>
      <StyledWrap>
        <StyledInner type={type}>
          <Header />
          {type === 'list' && isTotalAsset ? (
            <StyledTotalWrap>
              <StyledHeadLine>Total Assets</StyledHeadLine>
              <AssetsWrap>
                <StyledDollar>$ {totalAssetsDollar}</StyledDollar>
                <StyledWon>{totalAssetsWon}원</StyledWon>
              </AssetsWrap>
            </StyledTotalWrap>
          ) : (
            <StyledCardWrap>{children}</StyledCardWrap>
          )}
        </StyledInner>
      </StyledWrap>
    </GlobalPortal.Consumer>
  );
};

export default FixedTopHeader;

const StyledWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  background-color: ${colors.white};
  z-index: 20;
`;

const StyledInner = styled.div<{ type: HeaderType }>`
  position: relative;
  width: 100%;
  max-width: 575px;
  padding-bottom: ${({ type }) => (type === 'list' ? '38px' : '30px')};
  margin: 0 auto;

  border-radius: ${({ type }) => (type === 'list' ? '0px 0px 36px 36px' : '0')};
  background: ${({ type }) =>
    type === 'list'
      ? 'linear-gradient(180deg,rgba(160, 233, 188, 1) 0%,rgba(245, 245, 248, 1) 50%)'
      : 'linear-gradient(180deg,rgba(30, 215, 97, 0.4) 0%, rgba(30, 215, 97, 0) 100%)'};
  border-bottom: ${({ type }) => (type === 'card' ? '10px solid #F2F2F7' : '')};
  z-index: 20;
`;

// List UI -> Total 영역
const StyledTotalWrap = styled.div`
  padding: 0 20px;
  margin-top: 32px;
  box-sizing: border-box;
`;

const StyledHeadLine = styled.h2`
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  color: ${colors.main02};
`;

const AssetsWrap = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;

const StyledDollar = styled.h1`
  color: ${colors.grey12};
  font-size: 32px;
  font-weight: 700;
  line-height: 41px;
`;

const StyledWon = styled.p`
  color: ${colors.grey05};
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.408px;
`;

// Card UI -> Card Wrap 영역
const StyledCardWrap = styled.div`
  padding: 24px 16px 0;
  box-sizing: border-box;
`;
