import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { GlobalPortal } from '_/GlobalPortal';
import { Line, LockUp, LockUp_fill, Wallet, Wallet_fill } from '_/assets';
import { colors } from '_/constants';

const FixedBottomNav = () => {
  const [activeMenu, setActiveMenu] = useState<string>();
  const { pathname } = useLocation();

  useEffect(() => {
    if (!pathname) return;
    const splitPath = pathname.split('/');

    setActiveMenu(splitPath[1]);
  }, []);

  return (
    <GlobalPortal.Consumer>
      <StyledWrap>
        <StyledInner className="element-blur">
          <NavEleWrap>
            <NavLink to="/points">
              {activeMenu === 'points' ? <Wallet_fill /> : <Wallet />}
              <StyledText>지갑</StyledText>
            </NavLink>
            <Line />
            <NavLink to="/lock-up-list">
              {activeMenu === 'lock-up-list' ? <LockUp_fill /> : <LockUp />}
              <StyledText>락업</StyledText>
            </NavLink>
          </NavEleWrap>
        </StyledInner>
      </StyledWrap>
    </GlobalPortal.Consumer>
  );
};

export default FixedBottomNav;

const StyledWrap = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.05);
`;

const StyledInner = styled.div`
  max-width: 575px;
  margin: 0 auto;
  display: flex;
  height: 50px;
  padding: 10px 73px 0px 74px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(40px);
`;

const NavEleWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 74px;
`;

const NavLink = styled(Link)`
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 3px;
`;

const StyledText = styled.p`
  color: ${colors.grey12};
  text-align: center;
  font-size: 9px;
  font-weight: 400;
`;
