import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { Card, FixedBottomNav, FixedTopHeader, Text } from '_/components';
import { useApiLockUp, useLoading } from '_/hooks';
import { userState } from '_/recoil';
import { ICardItem, ILockUpList } from '_/types';

const LockUpList = () => {
  const { renderLoading } = useLoading();
  const userInfo = useRecoilValue(userState);
  const { data: lockUpData, isSuccess, isLoading } = useApiLockUp(userInfo._id);
  const [lockUpList, setLockUpList] = useState<ILockUpList>();

  useEffect(() => {
    renderLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setLockUpList(lockUpData.data);
  }, [lockUpData, isSuccess]);

  if (!lockUpList) return null;
  const { totalAssetsDollar, totalAssetsWon, lockups } = lockUpList;
  return (
    <>
      <div className="layout">
        <FixedTopHeader
          type="list"
          totalAssetsDollar={totalAssetsDollar}
          totalAssetsWon={totalAssetsWon}
        />
        <StyledWrap>
          <Text text="Lock up" />
          <StyledCardWrap>
            {lockups.map((lockup: ICardItem, idx) => (
              <Card {...lockup} key={idx} />
            ))}
          </StyledCardWrap>
        </StyledWrap>
      </div>
      <FixedBottomNav />
    </>
  );
};

export default LockUpList;

const StyledWrap = styled.div`
  margin: 205px 0 130px;
`;

const StyledCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
