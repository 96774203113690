import React from 'react';

const BitcashLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="80"
      viewBox="0 0 81 80"
      fill="none"
    >
      <g clipPath="url(#clip0_158_15155)">
        <path
          d="M40.5 80C62.5914 80 80.5 62.0914 80.5 40C80.5 17.9086 62.5914 0 40.5 0C18.4086 0 0.5 17.9086 0.5 40C0.5 62.0914 18.4086 80 40.5 80Z"
          fill="#1ED761"
        />
        <path
          d="M52.9772 26.5685C50.9671 22.0101 46.3478 21.0355 40.693 21.9796L38.8757 14.934L34.5914 16.0406L36.3782 23.0659C35.2513 23.3502 34.094 23.5939 32.9468 23.9187L31.16 16.934L26.8757 18.0406L28.693 25.0862C27.7691 25.3502 20.0432 27.3299 20.0432 27.3299L21.2209 31.9187C21.2209 31.9187 24.3681 31.0355 24.3376 31.1065C26.0838 30.6497 26.9062 31.5228 27.2919 32.3451L32.2869 51.6548C32.3478 52.2131 32.2463 53.1675 31.0483 53.4923C31.1194 53.5329 27.9315 54.2944 27.9315 54.2944L28.3985 59.6446C28.3985 59.6446 36.0534 57.6852 37.0584 57.4314L38.896 64.5583L43.1803 63.4517L41.3427 56.2741C42.5204 56 43.6676 55.7157 44.7843 55.4213L46.6117 62.5583L50.896 61.4517L49.0584 54.335C55.6574 52.7309 60.3173 48.5685 59.363 42.203C58.7539 38.3654 54.561 35.2182 51.0787 34.8629C53.2209 32.9644 54.3072 30.1928 52.9772 26.5685ZM50.9163 43.3705C51.7691 49.6751 43.0077 50.4467 40.1143 51.2081L37.5965 41.7766C40.5001 41.0152 49.4747 37.8172 50.9163 43.3705ZM45.6371 30.5279C46.5407 36.1319 39.0483 36.7817 36.632 37.401L34.3376 28.8426C36.764 28.2436 43.7996 25.3401 45.6371 30.5279Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_158_15155">
          <rect
            width="80"
            height="80"
            fill="white"
            transform="translate(0.5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BitcashLogo;
