import React from 'react';

const BitcashText = () => {
  return (
    <svg
      width="139"
      height="31"
      viewBox="0 0 139 31"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="logo">
        <path
          id="Vector"
          d="M12.7868 9.30194C14.1048 9.27188 15.414 9.53035 16.6233 10.0594C17.8325 10.5885 18.913 11.3756 19.7905 12.3662C21.6701 14.4523 22.6725 17.1913 22.5861 20.0063C22.6743 22.8235 21.6719 25.5647 19.7905 27.6522C18.9123 28.6416 17.8314 29.4279 16.6222 29.9567C15.4136 30.4858 14.1048 30.745 12.7868 30.7165C11.6487 30.7454 10.5161 30.5364 9.46244 30.1021C8.40852 29.6676 7.45538 29.0179 6.66401 28.1936V30.2285H0.848145V1.35254H6.66401V11.8248C7.45472 10.9994 8.40742 10.3487 9.46178 9.91438C10.5157 9.48001 11.6481 9.27146 12.7868 9.30194ZM8.0587 23.5824C9.01272 24.503 10.2834 25.0171 11.605 25.0171C12.9265 25.0171 14.1972 24.503 15.151 23.5824C16.0617 22.6067 16.5689 21.3183 16.5689 19.9795C16.5689 18.6405 16.0617 17.3522 15.151 16.3767C14.1972 15.4558 12.9265 14.9418 11.605 14.9418C10.2834 14.9418 9.01272 15.4558 8.0587 16.3767C7.14798 17.3522 6.64081 18.6405 6.64081 19.9795C6.64081 21.3183 7.14798 22.6067 8.0587 23.5824Z"
          fill="#040316"
        />
        <path
          id="Vector_2"
          d="M28.9869 7.16579C28.5149 7.1755 28.0449 7.08904 27.6064 6.91167C27.168 6.73424 26.7695 6.46951 26.4349 6.13338C26.1006 5.79727 25.8369 5.39659 25.6601 4.95545C25.4835 4.51423 25.3965 4.04168 25.4053 3.56595C25.3925 3.09245 25.478 2.62139 25.6559 2.18291C25.8338 1.74444 26.101 1.34816 26.4398 1.0193C26.7741 0.685556 27.1726 0.423392 27.6099 0.248587C28.0479 0.073693 28.516 -0.0102621 28.9869 0.00182617C29.4652 -0.013577 29.9416 0.0685887 30.3878 0.24346C30.8333 0.418332 31.2395 0.682219 31.5816 1.0193C31.9208 1.34787 32.1887 1.74396 32.3677 2.18243C32.5467 2.62083 32.6338 3.09203 32.6218 3.56595C32.6342 4.04352 32.5485 4.51846 32.3695 4.9609C32.1904 5.40331 31.9222 5.80357 31.5816 6.13643C31.2406 6.47563 30.8351 6.74173 30.3889 6.91868C29.9434 7.09558 29.4663 7.17965 28.9869 7.16579ZM26.0911 30.2285V9.78982H31.9007V30.2285H26.0911Z"
          fill="#040316"
        />
        <path
          id="Vector_3"
          d="M49.3242 15.1032H44.1115V22.6004C44.1115 24.2307 44.9607 25.0459 46.6588 25.0459C47.5925 25.0243 48.5068 24.7752 49.3242 24.32V29.8238C48.1238 30.4249 46.7994 30.7307 45.4591 30.7164C43.0712 30.7164 41.2863 30.0737 40.1042 28.7887C38.9221 27.5034 38.3312 25.7639 38.3312 23.5703V15.1032H35.2578V9.74809H38.3312V4.72021H44.1411V9.78975H49.3538L49.3242 15.1032Z"
          fill="#040316"
        />
        <path
          id="Vector_4"
          d="M62.1379 30.7166C60.6974 30.7566 59.263 30.5048 57.9209 29.9764C56.5792 29.4481 55.3558 28.6536 54.3244 27.6403C53.3013 26.6582 52.4933 25.472 51.9521 24.1575C51.4107 22.843 51.1477 21.4291 51.1802 20.0064C51.1477 18.5836 51.4107 17.1699 51.9525 15.8554C52.494 14.541 53.3019 13.3545 54.3244 12.3723C55.358 11.3595 56.5839 10.5658 57.928 10.0384C59.2718 9.51104 60.7074 9.26061 62.1496 9.30202C64.0687 9.27678 65.9617 9.74363 67.6521 10.6587C69.2574 11.5282 70.6008 12.8169 71.5411 14.3894L66.476 17.4835C66.0439 16.71 65.4132 16.068 64.6497 15.6241C63.8866 15.1803 63.019 14.9511 62.1379 14.9607C61.48 14.9439 60.8256 15.0636 60.2152 15.3125C59.6053 15.5611 59.0526 15.9335 58.5916 16.4064C57.6809 17.3818 57.1737 18.6705 57.1737 20.0092C57.1737 21.3482 57.6809 22.6366 58.5916 23.6121C59.0526 24.085 59.6053 24.4574 60.2152 24.7062C60.8256 24.9548 61.48 25.0746 62.1379 25.058C63.019 25.0675 63.8866 24.8384 64.6497 24.3944C65.4132 23.9506 66.0439 23.3084 66.476 22.5352L71.5292 25.6054C70.5959 27.1877 69.2514 28.4839 67.6402 29.3539C65.9518 30.2743 64.0574 30.7435 62.1379 30.7166Z"
          fill="#040316"
        />
        <path
          id="Vector_5"
          d="M88.8164 9.7899H94.6263V30.2286H88.8164V28.1937C88.0233 29.0155 87.0699 29.6639 86.0162 30.098C84.963 30.5322 83.8317 30.7426 82.6934 30.7165C81.3767 30.7433 80.0693 30.4823 78.8618 29.9512C77.655 29.42 76.577 28.6317 75.7016 27.6403C73.8163 25.5594 72.8097 22.8216 72.8943 20.0063C72.8104 17.1911 73.8168 14.4534 75.7016 12.3722C76.5781 11.383 77.6567 10.5963 78.8636 10.0663C80.0704 9.53635 81.3771 9.27562 82.6934 9.30197C83.8359 9.27032 84.9718 9.47827 86.0304 9.91264C87.0883 10.3469 88.0452 10.9981 88.8401 11.8248L88.8164 9.7899ZM83.8341 25.058C84.492 25.0739 85.1462 24.9539 85.7561 24.7051C86.3661 24.4565 86.9188 24.0845 87.3802 23.612C88.2911 22.6366 88.7981 21.3482 88.7981 20.0092C88.7981 18.6704 88.2911 17.3818 87.3802 16.4064C86.9188 15.9341 86.3661 15.5622 85.7561 15.3133C85.1462 15.0647 84.492 14.9445 83.8341 14.9606C83.1758 14.9394 82.5197 15.0572 81.9091 15.3063C81.2987 15.5553 80.7467 15.9301 80.2879 16.4064C79.3838 17.3854 78.8808 18.6724 78.8808 20.0092C78.8808 21.346 79.3838 22.6333 80.2879 23.612C80.7467 24.0885 81.2987 24.4633 81.9091 24.7121C82.5197 24.9612 83.1758 25.079 83.8341 25.058Z"
          fill="#040316"
        />
        <path
          id="Vector_6"
          d="M106.394 30.7165C103.213 30.7613 100.121 29.6548 97.6821 27.5987L100.383 23.8204C101.987 25.2498 104.056 26.0372 106.199 26.0336C107.008 26.0696 107.816 25.9234 108.563 25.6053C108.811 25.5174 109.027 25.3574 109.185 25.1457C109.343 24.9337 109.435 24.6796 109.449 24.4153C109.45 24.153 109.365 23.898 109.207 23.6893C108.981 23.4381 108.696 23.246 108.38 23.13C108.027 22.9806 107.666 22.8535 107.298 22.7492L105.832 22.3744L104.715 22.1245C104.448 22.0611 104.075 21.9598 103.598 21.8211C103.21 21.7139 102.827 21.5868 102.452 21.4403C102.162 21.3212 101.813 21.1605 101.4 20.9583C101.041 20.7912 100.704 20.5815 100.395 20.3336C100.147 20.1135 99.8748 19.8516 99.5794 19.5362C99.3033 19.2472 99.0775 18.9132 98.9115 18.5484C98.74 18.1656 98.6059 17.7672 98.5095 17.3583C98.3984 16.8766 98.3429 16.3835 98.344 15.8887C98.3179 14.5736 98.7347 13.288 99.5261 12.2413C100.306 11.2245 101.358 10.4526 102.558 10.0159C103.922 9.52834 105.361 9.2905 106.808 9.31382C109.723 9.29139 112.565 10.2319 114.899 11.9914L112.127 15.7579C110.623 14.6492 108.819 14.0266 106.955 13.9728C106.26 13.9468 105.566 14.0706 104.922 14.3358C104.695 14.4171 104.497 14.567 104.357 14.765C104.216 14.9628 104.139 15.1992 104.136 15.4425C104.136 15.6569 104.204 15.8655 104.331 16.0375C104.456 16.2173 104.615 16.3707 104.798 16.4896C105.052 16.6405 105.321 16.7623 105.602 16.8527C105.918 16.9647 106.24 17.06 106.565 17.1382L107.747 17.412L108.994 17.6916C109.302 17.7689 109.721 17.8761 110.247 18.0248C110.686 18.1468 111.116 18.304 111.53 18.4949C111.861 18.6615 112.239 18.8578 112.665 19.0898C113.059 19.2942 113.415 19.5658 113.717 19.8931C114.004 20.2118 114.267 20.5519 114.503 20.9106C114.765 21.3095 114.954 21.753 115.058 22.2197C115.181 22.7523 115.24 23.2975 115.236 23.844C115.275 24.8316 115.066 25.8131 114.629 26.6979C114.193 27.583 113.541 28.3428 112.735 28.9076C110.865 30.1713 108.644 30.8046 106.394 30.7165Z"
          fill="#040316"
        />
        <path
          id="Vector_7"
          d="M130.366 9.30194C131.4 9.25564 132.432 9.41993 133.4 9.7849C134.37 10.15 135.255 10.7082 136.004 11.4261C136.727 12.1693 137.289 13.0542 137.658 14.0255C138.025 14.997 138.191 16.0342 138.144 17.0728V30.2285H132.34V19.0483C132.367 18.5057 132.286 17.9634 132.102 17.4527C131.918 16.942 131.635 16.473 131.27 16.0733C130.901 15.6982 130.458 15.4054 129.969 15.2136C129.481 15.022 128.957 14.9358 128.433 14.9606C127.875 14.936 127.318 15.0244 126.793 15.2207C126.27 15.4169 125.79 15.7168 125.384 16.1029C124.989 16.4965 124.68 16.9683 124.476 17.4887C124.272 18.0092 124.179 18.5667 124.202 19.1256V30.2285H118.392V1.35254H124.202V11.9438C124.982 11.0852 125.936 10.405 126.999 9.94925C128.062 9.49352 129.211 9.27277 130.366 9.30194Z"
          fill="#040316"
        />
      </g>
    </svg>
  );
};

export default BitcashText;
