export const LoginValidation = {
  id: {
    value: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{5,20}$/g,
    message: '5-20자, 영문자와 숫자의 조합으로 입력해주세요.',
  },
} as const;

export const WalletValidation = {
  walletPassword: {
    value: /^[0-9]{4}$/,
    message: '4자리의 숫자로 입력해주세요.',
  },
} as const;

export const EmailValidation = {
  email: {
    value:
      /^[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*@[0-9a-zA-Z]([-_.]?[0-9a-zA-Z])*.[a-zA-Z]{2,3}$/i,
    message: '이메일 형식에 맞게 입력해주세요.',
  },
} as const;

export const PasswordValidation = {
  password: {
    value: /^[A-Za-z0-9]{8,20}$/,
    message: '8-20자, 영문자 또는 숫자의 조합으로 입력해주세요.',
  },
};

export const RegisterValidation = {
  id: LoginValidation.id,
  email: EmailValidation.email,
  password: PasswordValidation.password,
  walletPassword: WalletValidation.walletPassword,
  phone: {
    value: /^[0-9]*$/,
    message: '전화번호는 숫자로만 입력해주세요.',
  },
} as const;

export const WithdrawValidation = {
  walletPassword: WalletValidation.walletPassword,
  accountNumber: {
    value: /^[0-9]*$/,
    message: '계좌번호는 숫자로만 입력해주세요.',
  },
} as const;
