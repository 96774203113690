import { useMutation } from '@tanstack/react-query';
import { ExternalResponse, ISignUpRes, ISignUpReq } from '_/types';
import { generateUrl, postApi } from './basicApi';

export const useApiSignUp = () => {
  const url = generateUrl(`/v1/auth/signup`);
  const postSignUp = (
    signUpBody: ISignUpReq,
  ): Promise<ExternalResponse<ISignUpRes>> => postApi(url, signUpBody);

  return useMutation({ mutationFn: postSignUp });
};
