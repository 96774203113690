import React, { useEffect } from 'react';
import { Close } from 'src/assets';
import styled from 'styled-components';
import Button from '../Button';
import { GlobalPortal } from '_/GlobalPortal';
import { colors } from '_/constants';
import { useCheckBoxPopup, useLockBodyScroll } from '_/hooks';
import { ICheckBoxStatus } from '_/types';
import { privacy, signature, termsOfUse } from './popupData';

const CheckBoxPopup = () => {
  const [, setLocked] = useLockBodyScroll();
  const { checkBoxPopup, resetCheckBoxPopup } = useCheckBoxPopup();
  const { isOpen, type, onClick } = checkBoxPopup;

  useEffect(() => {
    if (!isOpen) {
      setLocked(false);
      return;
    }

    setLocked(true);
  }, [isOpen]);

  const getTypeInfo = (type: ICheckBoxStatus) => {
    switch (type) {
      case 'privacy':
        return privacy;
      case 'termsOfUse':
        return termsOfUse;
      case 'signature':
        return signature;
    }
  };

  const handleClickBtn = () => {
    if (onClick) {
      onClick();
    }

    resetCheckBoxPopup();
  };

  if (!isOpen) return null;
  const { title, contents } = getTypeInfo(type);
  return (
    <GlobalPortal.Consumer>
      <StyledDim />
      <StyledWrap>
        <StyledCloseButtonWrap>
          <StyledCloseButton onClick={handleClickBtn}>
            <Close />
          </StyledCloseButton>
        </StyledCloseButtonWrap>
        <StyledTextWrap>
          <StyledPopUpTitle>{title}</StyledPopUpTitle>
          <StyledContentsWrap dangerouslySetInnerHTML={{ __html: contents }} />
        </StyledTextWrap>
        <StyledConfirmButtonWrap>
          <Button text="동의하기" onClick={handleClickBtn} />
        </StyledConfirmButtonWrap>
      </StyledWrap>
    </GlobalPortal.Consumer>
  );
};

export default CheckBoxPopup;

const StyledDim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  z-index: 50;
  animation: fadeIn 200ms forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const StyledWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: ${colors.white};
  border-radius: 20px;
  max-width: 293px;
  width: 100%;
  height: 518px;
  padding: 12px 0 24px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  z-index: 50;
  animation: inLarge 200ms forwards;

  @keyframes inLarge {
    from {
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
`;

const StyledCloseButtonWrap = styled.div`
  width: 100%;
  text-align: right;
`;

const StyledCloseButton = styled.button`
  width: 28px;
  height: 28px;
  margin-right: 12px;
`;

const StyledTextWrap = styled.div`
  width: 100%;
  height: 100%;
  margin: 0 auto;
  padding: 0 24px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 28px;
`;

const StyledPopUpTitle = styled.h2`
  color: ${colors.grey12};
  font-size: 17px;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.408px;
`;

const StyledContentsWrap = styled.div`
  height: 100%;
  overflow: hidden;
  overflow-y: scroll;
  padding-bottom: 100px;
  box-sizing: border-box;

  color: ${colors.grey10};
  font-size: 12px;
  font-weight: 400;
  line-height: 17px;
  letter-spacing: -0.2px;
`;

const StyledConfirmButtonWrap = styled.div`
  position: fixed;
  bottom: 0;
  width: 100%;
  padding: 18px 20px 24px;
  box-sizing: border-box;
  border-radius: 0 0 20px 20px;
  background-color: ${colors.white};
`;
