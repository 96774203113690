import React from 'react';

const Right = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        d="M11.4707 7L12.1778 7.70711L12.8849 7L12.1778 6.29289L11.4707 7ZM1.4707 6C0.918419 6 0.470704 6.44771 0.470704 7C0.470704 7.55228 0.918418 8 1.4707 8L1.4707 6ZM6.17781 13.7071L12.1778 7.70711L10.7636 6.29289L4.7636 12.2929L6.17781 13.7071ZM12.1778 6.29289L6.17781 0.292893L4.7636 1.70711L10.7636 7.70711L12.1778 6.29289ZM11.4707 6L1.4707 6L1.4707 8L11.4707 8L11.4707 6Z"
        fill="#8E8E93"
      />
    </svg>
  );
};

export default Right;
