import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { popupState } from '_/recoil';

export const usePopup = () => {
  const [popup, setPopup] = useRecoilState(popupState);
  const resetPopup = useResetRecoilState(popupState);

  const openPopup = useCallback(
    (popupText: string, onClick?: () => void) => {
      setPopup({
        isOpen: true,
        text: popupText,
        onClick,
      });
    },
    [setPopup],
  );

  return {
    openPopup,
    resetPopup,
    popup,
  };
};
