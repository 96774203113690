import React from 'react';

const BitcashCardLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="76"
      height="18"
      viewBox="0 0 76 18"
      fill="none"
    >
      <g clipPath="url(#clip0_160_174)">
        <mask
          id="mask0_160_174"
          maskUnits="userSpaceOnUse"
          x="0"
          y="0"
          width="77"
          height="18"
        >
          <path d="M76.0645 0H0V17.9497H76.0645V0Z" fill="white" />
        </mask>
        <g mask="url(#mask0_160_174)">
          <path
            d="M29.1255 6.56279C29.6186 6.55146 30.1084 6.64893 30.5608 6.84844C31.0132 7.04793 31.4175 7.34473 31.7458 7.7183C32.449 8.50492 32.824 9.53775 32.7917 10.5993C32.8247 11.6616 32.4496 12.6953 31.7458 13.4824C31.4172 13.8555 31.0128 14.152 30.5604 14.3514C30.1082 14.5509 29.6186 14.6487 29.1255 14.6379C28.6997 14.6488 28.276 14.57 27.8818 14.4063C27.4875 14.2424 27.1309 13.9974 26.8348 13.6866V14.4539H24.659V3.56519H26.8348V7.51413C27.1306 7.20288 27.4871 6.95753 27.8815 6.79374C28.2758 6.62994 28.6995 6.5513 29.1255 6.56279ZM27.3566 11.9478C27.7135 12.2949 28.1889 12.4888 28.6834 12.4888C29.1778 12.4888 29.6532 12.2949 30.01 11.9478C30.3507 11.5798 30.5405 11.094 30.5405 10.5892C30.5405 10.0843 30.3507 9.59842 30.01 9.23059C29.6532 8.88334 29.1778 8.6895 28.6834 8.6895C28.1889 8.6895 27.7135 8.88334 27.3566 9.23059C27.0159 9.59842 26.8261 10.0843 26.8261 10.5892C26.8261 11.094 27.0159 11.5798 27.3566 11.9478Z"
            fill="#040316"
          />
          <path
            d="M35.1861 5.7573C35.0095 5.76096 34.8336 5.72836 34.6696 5.66147C34.5056 5.59456 34.3565 5.49474 34.2313 5.36799C34.1062 5.24125 34.0076 5.09016 33.9415 4.92381C33.8754 4.75743 33.8428 4.57924 33.8461 4.39985C33.8413 4.2213 33.8733 4.04366 33.9399 3.87832C34.0064 3.71298 34.1064 3.56355 34.2331 3.43954C34.3582 3.31369 34.5073 3.21483 34.6709 3.14891C34.8348 3.08296 35.0099 3.05131 35.1861 3.05586C35.365 3.05006 35.5433 3.08104 35.7102 3.14698C35.8769 3.21292 36.0289 3.31243 36.1568 3.43954C36.2837 3.56344 36.384 3.7128 36.4509 3.87814C36.5179 4.04346 36.5505 4.22114 36.546 4.39985C36.5506 4.57993 36.5186 4.75902 36.4516 4.92586C36.3846 5.09269 36.2842 5.24362 36.1568 5.36914C36.0293 5.49705 35.8775 5.59739 35.7106 5.66411C35.5439 5.73082 35.3654 5.76252 35.1861 5.7573ZM34.1027 14.4539V6.74678H36.2762V14.4539H34.1027Z"
            fill="#040316"
          />
          <path
            d="M42.795 8.75025H40.8448V11.5773C40.8448 12.1921 41.1625 12.4995 41.7978 12.4995C42.1471 12.4913 42.4892 12.3974 42.795 12.2258V14.3012C42.3459 14.5278 41.8504 14.6432 41.3489 14.6378C40.4556 14.6378 39.7878 14.3954 39.3455 13.9108C38.9033 13.4262 38.6822 12.7703 38.6822 11.9431V8.75025H37.5324V6.7309H38.6822V4.83496H40.8558V6.74661H42.8061L42.795 8.75025Z"
            fill="#040316"
          />
          <path
            d="M47.5894 14.6378C47.0505 14.6529 46.5139 14.558 46.0117 14.3587C45.5098 14.1595 45.0521 13.8599 44.6662 13.4778C44.2834 13.1075 43.9812 12.6602 43.7787 12.1645C43.5761 11.6688 43.4777 11.1357 43.4899 10.5992C43.4777 10.0627 43.5761 9.52957 43.7788 9.03391C43.9814 8.53824 44.2837 8.09085 44.6662 7.72046C45.0529 7.33853 45.5115 7.03927 46.0144 6.84038C46.5172 6.64152 47.0542 6.54708 47.5938 6.5627C48.3118 6.55318 49.02 6.72922 49.6524 7.07427C50.253 7.40216 50.7556 7.88811 51.1074 8.48107L49.2124 9.64782C49.0508 9.35616 48.8148 9.11407 48.5292 8.94666C48.2437 8.77932 47.9191 8.69291 47.5894 8.69649C47.3433 8.69016 47.0985 8.73532 46.8701 8.82916C46.6419 8.92291 46.4351 9.06332 46.2627 9.24166C45.922 9.60949 45.7322 10.0954 45.7322 10.6002C45.7322 11.1052 45.922 11.591 46.2627 11.9588C46.4351 12.1372 46.6419 12.2776 46.8701 12.3714C47.0985 12.4652 47.3433 12.5103 47.5894 12.5041C47.9191 12.5077 48.2437 12.4212 48.5292 12.2538C48.8148 12.0865 49.0508 11.8443 49.2124 11.5527L51.1029 12.7105C50.7538 13.3072 50.2508 13.7959 49.648 14.124C49.0163 14.4711 48.3076 14.648 47.5894 14.6378Z"
            fill="#040316"
          />
          <path
            d="M57.5701 6.7467H59.7437V14.4538H57.5701V13.6865C57.2733 13.9964 56.9167 14.2409 56.5224 14.4046C56.1284 14.5683 55.7052 14.6477 55.2793 14.6378C54.7867 14.6479 54.2976 14.5495 53.8458 14.3493C53.3943 14.1489 52.991 13.8517 52.6635 13.4778C51.9582 12.6932 51.5816 11.6608 51.6132 10.5992C51.5818 9.53759 51.9583 8.50526 52.6635 7.72047C52.9914 7.34743 53.3949 7.05079 53.8465 6.85094C54.298 6.65109 54.7868 6.55277 55.2793 6.56271C55.7067 6.55077 56.1317 6.62919 56.5277 6.79298C56.9235 6.95675 57.2815 7.20231 57.5789 7.51405L57.5701 6.7467ZM55.7061 12.5041C55.9522 12.5101 56.1969 12.4648 56.4251 12.371C56.6533 12.2773 56.8601 12.137 57.0327 11.9588C57.3735 11.591 57.5632 11.1052 57.5632 10.6003C57.5632 10.0954 57.3735 9.60951 57.0327 9.24167C56.8601 9.06359 56.6533 8.92334 56.4251 8.82951C56.1969 8.73576 55.9522 8.69042 55.7061 8.69651C55.4598 8.68851 55.2143 8.73292 54.9859 8.82684C54.7575 8.92076 54.551 9.06209 54.3793 9.24167C54.0411 9.61084 53.8529 10.0962 53.8529 10.6003C53.8529 11.1043 54.0411 11.5898 54.3793 11.9588C54.551 12.1385 54.7575 12.2798 54.9859 12.3737C55.2143 12.4676 55.4598 12.512 55.7061 12.5041Z"
            fill="#040316"
          />
          <path
            d="M64.1466 14.6379C62.9565 14.6548 61.7998 14.2375 60.8873 13.4622L61.8978 12.0375C62.4979 12.5765 63.272 12.8734 64.0736 12.872C64.3765 12.8856 64.6788 12.8305 64.9581 12.7105C65.0509 12.6774 65.1319 12.617 65.1909 12.5372C65.2499 12.4573 65.2844 12.3615 65.2897 12.2618C65.29 12.1629 65.2581 12.0667 65.1991 11.988C65.1144 11.8933 65.008 11.8209 64.8895 11.7771C64.7578 11.7208 64.6227 11.6729 64.4849 11.6335L63.9365 11.4922L63.5186 11.398C63.4184 11.374 63.2791 11.3359 63.1007 11.2835C62.9554 11.2431 62.8122 11.1952 62.6717 11.14C62.5634 11.095 62.4329 11.0345 62.2781 10.9582C62.1439 10.8952 62.0179 10.8161 61.9022 10.7226C61.8093 10.6396 61.7077 10.5409 61.5971 10.422C61.4938 10.313 61.4093 10.187 61.3473 10.0495C61.2831 9.90513 61.2329 9.75488 61.1969 9.60072C61.1553 9.41905 61.1345 9.23313 61.1349 9.04655C61.1252 8.55063 61.2811 8.06586 61.5772 7.67115C61.869 7.28772 62.2624 6.99665 62.7115 6.832C63.2217 6.64813 63.7601 6.55845 64.3014 6.56724C65.3922 6.55878 66.4555 6.91345 67.3285 7.57691L66.2914 8.99722C65.7287 8.57913 65.0538 8.34438 64.3566 8.32407C64.0964 8.31426 63.837 8.36096 63.596 8.46096C63.5109 8.49163 63.437 8.54813 63.3844 8.6228C63.3318 8.69738 63.303 8.78655 63.3019 8.8783C63.3017 8.95913 63.3273 9.0378 63.3749 9.10263C63.4217 9.17047 63.481 9.2283 63.5496 9.27313C63.6444 9.33005 63.7453 9.37596 63.8503 9.41005C63.9686 9.4523 64.0889 9.48821 64.2107 9.51771L64.6529 9.62097L65.1195 9.72638C65.2345 9.75555 65.3915 9.79596 65.5883 9.85205C65.7525 9.89805 65.9131 9.9573 66.068 10.0293C66.1919 10.0921 66.3334 10.1661 66.4926 10.2536C66.6401 10.3307 66.7735 10.4331 66.8862 10.5565C66.9936 10.6767 67.0921 10.805 67.1803 10.9402C67.2785 11.0906 67.349 11.2579 67.3882 11.4339C67.4339 11.6347 67.4562 11.8403 67.4545 12.0464C67.4691 12.4188 67.391 12.7889 67.2276 13.1225C67.0642 13.4563 66.8205 13.7428 66.5191 13.9558C65.8194 14.4323 64.9886 14.6711 64.1466 14.6379Z"
            fill="#040316"
          />
          <path
            d="M73.1146 6.56279C73.5014 6.54534 73.8874 6.60729 74.2498 6.74491C74.6125 6.88257 74.9437 7.09307 75.224 7.3638C75.4943 7.64404 75.7048 7.9777 75.8426 8.344C75.9801 8.71034 76.0422 9.10142 76.0245 9.49308V14.4539H73.8531V10.238C73.8631 10.0334 73.8328 9.82892 73.764 9.63634C73.6952 9.44375 73.5896 9.26692 73.4529 9.11617C73.3147 8.97475 73.1489 8.86434 72.9663 8.792C72.7834 8.71975 72.5875 8.68725 72.3916 8.69659C72.1826 8.68734 71.9741 8.72067 71.7779 8.79467C71.582 8.86867 71.4027 8.98175 71.2506 9.12734C71.1029 9.27575 70.9873 9.45367 70.9109 9.64992C70.8349 9.84617 70.8 10.0564 70.8083 10.2672V14.4539H68.6348V3.56519H70.8083V7.559C71.1002 7.23524 71.4571 6.97875 71.8549 6.80689C72.2527 6.63504 72.6823 6.55179 73.1146 6.56279Z"
            fill="#040316"
          />
          <path
            d="M8.82927 17.9183C13.7055 17.9183 17.6585 13.9072 17.6585 8.95917C17.6585 4.01116 13.7055 0 8.82927 0C3.95299 0 0 4.01116 0 8.95917C0 13.9072 3.95299 17.9183 8.82927 17.9183Z"
            fill="#1ED761"
          />
          <path
            d="M11.8071 5.97787C11.3548 4.95224 10.3155 4.73295 9.04317 4.94539L8.63428 3.36011L7.67032 3.60909L8.07235 5.1898C7.8188 5.25376 7.55839 5.30858 7.30027 5.38168L6.89824 3.81011L5.93428 4.05909L6.34317 5.64437C6.1353 5.70376 4.39697 6.14919 4.39697 6.14919L4.66195 7.18168C4.66195 7.18168 5.37007 6.98295 5.36322 6.99894C5.75611 6.89615 5.94113 7.0926 6.02794 7.27762L7.15179 11.6223C7.1655 11.7479 7.14266 11.9626 6.87311 12.0357C6.8891 12.0449 6.17185 12.2162 6.17185 12.2162L6.27692 13.42C6.27692 13.42 7.99926 12.9791 8.2254 12.922L8.63885 14.5256L9.60281 14.2766L9.18936 12.6616C9.45433 12.6 9.71245 12.536 9.96372 12.4698L10.3749 14.0756L11.3389 13.8266L10.9254 12.2253C12.4102 11.8644 13.4586 10.9279 13.2439 9.49564C13.1069 8.63219 12.1635 7.92407 11.38 7.84412C11.8619 7.41696 12.1064 6.79336 11.8071 5.97787ZM11.3434 9.75833C11.5353 11.1769 9.56398 11.3505 8.91296 11.5218L8.34646 9.3997C8.99976 9.22838 11.0191 8.50884 11.3434 9.75833ZM10.1556 6.86874C10.3589 8.12965 8.67311 8.27584 8.12946 8.41518L7.61322 6.48955C8.15915 6.35478 9.74215 5.70148 10.1556 6.86874Z"
            fill="white"
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0_160_174">
          <rect width="76.0645" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default BitcashCardLogo;
