import React from 'react';
import styled from 'styled-components';

const Background = () => {
  return (
    <BackGroundSection>
      <BackGround />
    </BackGroundSection>
  );
};

export default Background;

const BackGroundSection = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
`;

const BackGround = styled.div`
  width: 100%;
  max-width: 575px;
  margin: 0 auto;
  height: 128px;
  background: linear-gradient(180deg, #9fe9bc 0%, rgba(30, 215, 97, 0) 100%);
  z-index: 0;
`;
