import { useQuery } from '@tanstack/react-query';
import { ExternalResponse, IDeposit } from '_/types';
import { fetchApi, generateUrl } from './basicApi';

const CHECK_DUPLICATED_STATE_KEY = 'bitcash/CHECK_DUPLICATED';

export const useApiCheckDuplicated = (userId: string) => {
  const url = generateUrl(`/v1/auth/check-duplicated?userId=${userId}`);

  const fetchCheck = (): Promise<ExternalResponse<IDeposit[]>> => fetchApi(url);

  return useQuery({
    queryKey: [CHECK_DUPLICATED_STATE_KEY, userId],
    queryFn: fetchCheck,
    enabled: !!userId && !!userId.length,
    retry: false,
  });
};
