import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Badge from '../Badge';
import Button from '../Button';
import FixedBottomPopup from '../FixedBottomPopup';
import { BitcashCardLogo, Left, Line_grey, Line_white, Right } from '_/assets';
import { colors } from '_/constants';
import { useApiDepositAddress } from '_/hooks';
import { ICardItem } from '_/types';

interface ICard extends ICardItem {
  isButton?: boolean;
}

const Card = ({ isButton, _id, bitcoinCash, dollar, won }: ICard) => {
  const navigate = useNavigate();
  const [isDepositPopupOpen, setIsDepositPopupOpen] = useState<boolean>(false);
  const { data: addressData } = useApiDepositAddress();

  const handleClickDeposit = () => {
    setIsDepositPopupOpen(true);
  };

  const handleClickWithdraw = () => {
    navigate(`withdraw/${_id}`);
  };

  const getButtonText = (type: 'deposit' | 'withdraw') => {
    if (type === 'deposit') {
      return (
        <ButtonInner>
          <IconWrap>
            <Right />
            <Line_grey />
          </IconWrap>
          입금
        </ButtonInner>
      );
    }

    return (
      <ButtonInner>
        <IconWrap>
          <Left />
          <Line_white />
        </IconWrap>
        출금
      </ButtonInner>
    );
  };

  return (
    <>
      <FixedBottomPopup
        isOpen={isDepositPopupOpen}
        setIsOpen={setIsDepositPopupOpen}
        address={addressData?.data}
      />
      <StyledWrap>
        <StyledInner>
          <StyledAssetWrap>
            <AssetTop>
              <BitcashCardLogo />
              <Badge badgeType="DEFAULT" />
            </AssetTop>
            <AssetBottom>
              <BottomHeadLineWrap>
                <HeadLine
                  dangerouslySetInnerHTML={{
                    __html: bitcoinCash,
                  }}
                />
              </BottomHeadLineWrap>
              <BottomTextWrap>
                <StyledText>₩ {won}</StyledText>
                <StyledText>$ {dollar}</StyledText>
              </BottomTextWrap>
            </AssetBottom>
          </StyledAssetWrap>
          {!!isButton && (
            <StyledButtonWrap>
              <Button
                text={getButtonText('deposit')}
                colorType="grey"
                onClick={handleClickDeposit}
              />
              <Button
                text={getButtonText('withdraw')}
                onClick={handleClickWithdraw}
              />
            </StyledButtonWrap>
          )}
        </StyledInner>
      </StyledWrap>
    </>
  );
};

export default Card;

const StyledWrap = styled.div`
  width: 100%;
  border-radius: 20px;
  border: 0.5px solid ${colors.grey01};
  background: ${colors.white};
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  padding: 14px 16px;
  box-sizing: border-box;
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 18px;
`;

const StyledAssetWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const AssetTop = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const AssetBottom = styled.div`
  display: flex;
  padding: 2px 0px;
  justify-content: space-between;
`;

const BottomHeadLineWrap = styled.div`
  display: flex;
  align-items: center;
`;

const HeadLine = styled.h1`
  color: ${colors.grey12};
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 30px; /* 125% */

  span {
    margin-left: 6px;
    color: ${colors.grey12};
    text-align: center;
    font-size: 16px;
    font-weight: 600;
    line-height: 21px; /* 131.25% */
  }
`;

const BottomTextWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const StyledText = styled.p`
  color: ${colors.grey05};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
`;

const StyledButtonWrap = styled.div`
  display: flex;
  gap: 10px;
`;

const ButtonInner = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

const IconWrap = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;
