export const colors = {
  white: '#FFFFFF',
  grey00: '#F5F5F8',
  grey01: '#F2F2F7',
  grey02: '#E5E5EA',
  grey03: '#D1D1D6',
  grey04: '#C7C7CC',
  grey05: '#AEAEB2',
  grey06: '#8E8E93',
  grey07: '#8E8E93',
  grey08: '#636366',
  grey09: '#48484A',
  grey10: '#3A3A3C',
  grey11: '#1C1C1E',
  grey12: '#040216',
  black: '#000000',
  main00: '#DEF9E8',
  main01: '#5BE98F',
  main02: '#1ED761',
  red: '#FF4848',
};
