import React, { useEffect } from 'react';
import Cookies from 'js-cookie';
import { SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import styled from 'styled-components';
import { BitcashLogo, BitcashText } from '_/assets';
import { Background, Button, Input } from '_/components';
import { ACCESS_TOKEN, colors } from '_/constants';
import { useApiLoginState, useLoading, usePopup } from '_/hooks';
import { userState } from '_/recoil';
import { IMutateStatus } from '_/types';

interface IFormInput {
  id: string;
  password: string;
}

const defaultValue = {
  id: '',
  password: '',
};

const Main = () => {
  const navigate = useNavigate();
  const { openPopup } = usePopup();
  const { renderLoading } = useLoading();

  const setUserState = useSetRecoilState(userState);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IFormInput>();

  const { mutate, data: userData, status, isPending } = useApiLoginState();

  useEffect(() => {
    renderLoading(isPending);
  }, [isPending]);

  useEffect(() => {
    handleLoginState(status);
  }, [userData, status]);

  const handleLoginState = (status: IMutateStatus) => {
    switch (status) {
      case 'success': {
        const { data } = userData;
        const { token, name, isFirstLogin } = data;

        Cookies.set(ACCESS_TOKEN, token);
        setUserState(data);

        if (isFirstLogin) {
          openPopup('처음 로그인 하셨네요! 비밀번호를 변경해주세요.', () =>
            navigate('/account-update'),
          );
          break;
        }

        openPopup(`${name} 회원님 반갑습니다.`, () => navigate('/points'));
        break;
      }
      case 'error': {
        openPopup('아이디 또는 비밀번호가 일치하지 않습니다.');
      }
    }
  };

  const handleSubmitBtn: SubmitHandler<IFormInput> = async (data) => {
    if (Object.keys(data).length === 0) return;
    mutate(data);
  };

  return (
    <>
      <div className="layout">
        <StyledWrap>
          <StyledInnerForm onSubmit={handleSubmit(handleSubmitBtn)}>
            <InnerTop>
              <LogoWrap>
                <BitcashLogo />
                <BitcashText />
              </LogoWrap>
              <LoginArea>
                <InputWrap>
                  <Input
                    placeholderText="아이디 입력"
                    type="text"
                    {...register('id', {
                      required: '아이디는 필수 입력입니다.',
                      // pattern: LoginValidation.id,
                    })}
                    warningText={errors.id && errors.id.message}
                  />
                  <Input
                    placeholderText="비밀번호 입력"
                    type="password"
                    {...register('password', {
                      required: '비밀번호는 필수 입력입니다.',
                    })}
                    warningText={errors.password && errors.password.message}
                  />
                </InputWrap>
                <Button type="submit" text="로그인" disabled={isPending} />
              </LoginArea>
            </InnerTop>
            <InnerBottom>
              <StyledLink to="/sign-up">회원가입</StyledLink>
              <StyledLink to="/account-find">ID/Password 찾기</StyledLink>
            </InnerBottom>
          </StyledInnerForm>
        </StyledWrap>
      </div>
      <Background />
    </>
  );
};

export default Main;

const StyledWrap = styled.div`
  margin-top: 66px;
`;

const StyledInnerForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const InnerTop = styled.div`
  display: flex;
  flex-direction: column;
  gap: 64px;
`;

const InnerBottom = styled.div`
  display: flex;
  gap: 16px;
  margin: 0 auto;
  color: ${colors.grey10};
`;

const StyledLink = styled(Link)`
  &:last-child {
    &::before {
      content: '|';
      padding-right: 16px;
      color: ${colors.grey02};
    }
  }
`;

const LoginArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const LogoWrap = styled.div`
  width: 140px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 0 auto;
  z-index: 10;
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
