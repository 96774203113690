import React, { ComponentPropsWithoutRef, Ref, forwardRef } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { GlobalPortal } from '_/GlobalPortal';
import { colors } from '_/constants';

const FixedBottomBtn = forwardRef(function FixedBottomCTA(
  props: ComponentPropsWithoutRef<typeof Button>,
  forwardedRef: Ref<HTMLButtonElement>,
) {
  return (
    <GlobalPortal.Consumer>
      <StyledWrap>
        <StyledButtonWrap>
          <Button {...props} ref={forwardedRef} />
        </StyledButtonWrap>
      </StyledWrap>
    </GlobalPortal.Consumer>
  );
});

export default FixedBottomBtn;

const StyledWrap = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  box-shadow: 0px -3px 5px 0px rgba(0, 0, 0, 0.05);
`;

const StyledButtonWrap = styled.div`
  box-sizing: border-box;
  padding: 10px 16px 34px 16px;
  background-color: ${colors.white};
  max-width: 575px;
  margin: 0 auto;
`;
