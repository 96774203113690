import { useQuery } from '@tanstack/react-query';
import { ExternalResponse, IDeposit, UserIdType } from '_/types';
import { fetchApi, generateUrl } from './basicApi';

const DEPOSIT_LIST_STATE_KEY = 'bitcash/DEPOSIT_LIST';

export const useApiDepositList = (userId: UserIdType) => {
  const url = generateUrl(`/v1/users/${userId}/deposit`);

  const fetchDepositList = (): Promise<ExternalResponse<IDeposit[]>> =>
    fetchApi(url);

  return useQuery({
    queryKey: [DEPOSIT_LIST_STATE_KEY, userId],
    queryFn: fetchDepositList,
    // staleTime: Infinity,
    enabled: !!userId,
    retry: 3,
  });
};
