import { useQuery } from '@tanstack/react-query';
import { ExternalResponse, ILockUpList, UserIdType } from '_/types';
import { fetchApi, generateUrl } from './basicApi';

const LOCKUP_STATE_KEY = 'bitcash/LOCKUP';

export const useApiLockUp = (userId: UserIdType) => {
  const url = generateUrl(`/v1/users/${userId}/lockups`);

  const fetchLockUp = (): Promise<ExternalResponse<ILockUpList>> =>
    fetchApi(url);

  return useQuery({
    queryKey: [LOCKUP_STATE_KEY, userId],
    queryFn: fetchLockUp,
    enabled: !!userId,
    retry: 3,
  });
};
