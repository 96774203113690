import React, { useEffect } from 'react';
import styled from 'styled-components';
import Button from '../Button';
import { GlobalPortal } from '_/GlobalPortal';
import { colors } from '_/constants';
import { useLockBodyScroll, usePopup } from '_/hooks';

const Popup = () => {
  const [, setLocked] = useLockBodyScroll();
  const { popup, resetPopup } = usePopup();
  const { isOpen, text, onClick } = popup;

  useEffect(() => {
    if (!isOpen) {
      setLocked(false);
      return;
    }

    setLocked(true);
  }, [isOpen]);

  const handleClickBtn = () => {
    if (onClick) {
      onClick();
    }

    resetPopup();
  };

  if (!isOpen) return null;
  return (
    <GlobalPortal.Consumer>
      <StyledDim />
      <StyledWrap>
        <StyledPopupText>{text}</StyledPopupText>
        <Button text="확인" onClick={handleClickBtn} />
      </StyledWrap>
    </GlobalPortal.Consumer>
  );
};

export default Popup;

const StyledDim = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 50;
  animation: fadeIn 200ms forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const StyledWrap = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: ${colors.white};
  border-radius: 18px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 260px;
  padding: 30px 20px 20px 20px;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  z-index: 50;
  animation: inLarge 200ms forwards;

  @keyframes inLarge {
    from {
      transform: translate(-50%, -50%) scale(0.8);
      opacity: 0;
    }
    to {
      transform: translate(-50%, -50%) scale(1);
      opacity: 1;
    }
  }
`;

const StyledPopupText = styled.h2`
  color: ${colors.grey12};
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 21px;
  letter-spacing: -0.32px;
`;
