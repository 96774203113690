import React from 'react';

const Back = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="26"
      viewBox="0 0 2 26"
      fill="none"
    >
      <path
        d="M1 0L0.999999 26"
        stroke="#E5E5EA"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default Back;
