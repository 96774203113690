import { atom } from 'recoil';
import { ICheckBoxStatus } from '_/types';

interface ICheckBoxPopupState {
  isOpen: boolean;
  type?: ICheckBoxStatus;
  onClick?: () => void;
}

// checkbox Popup
export const checkBoxPopupState = atom<ICheckBoxPopupState>({
  key: 'checkBoxPopupState',
  default: {
    isOpen: false,
  },
});
