import { atom } from 'recoil';

interface IPopupState {
  isOpen: boolean;
  text: string;
  onClick?: () => void;
}

// default Popup
export const popupState = atom<IPopupState>({
  key: 'popUpState',
  default: {
    isOpen: false,
    text: '',
  },
});
