import { useMutation } from '@tanstack/react-query';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN } from '_/constants';
import { ExternalResponse, ISignUpRes, UserIdType } from '_/types';
import { generateUrl, postApi } from './basicApi';

interface IAgree {
  userId: UserIdType;
  file: File;
}

export const useApiAgreeSignature = () => {
  const postAgree = ({
    userId,
    file,
  }: IAgree): Promise<ExternalResponse<ISignUpRes>> => {
    const accessToken = Cookies.get(ACCESS_TOKEN);
    const url = generateUrl(`/v1/users/${userId}/agreementElectronicContract`);

    return postApi(
      url,
      {
        file,
      },
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${accessToken}`,
        },
      },
    );
  };

  return useMutation({ mutationFn: postAgree });
};
