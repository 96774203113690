export const privacy = {
  title: "개인정보처리방침",
  contents: `<div className="page-body">
  <p id="590d9b1e-7685-43f8-b044-a4cc84bd379d" className="">
    총칙 Bitcash (이하 &#x27;회사&#x27;)는 온라인상에서 ‘통신비밀보호법’,
    ‘전기통신사업법’, ‘정보통신망 이용촉진 및 정보보호 등에 관한 법률’ 등
    정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을
    준수하며, 관련 법령에 의거한 개인정보취급방침을 정하여 회원의 개인정보
    보호에 최선을 다하고 있습니다. 회사의 개인정보보호는 다음과 같이
    이루어지고 있습니다. 회사는 개인정보보호방침을 통하여 회원이 제공하는
    개인정보를 관련법률 및 정부지침의 변경과 더불어 내부 신규서비스의 추가와
    서비스 확대시 이에 따른 정책이 추가/변동될 수 있으므로 어떠한 용도와
    방식으로 이용되고 있으며 개인정보보호를 위해 어떠한 조치가 취해지는지
    홈페이지 첫 화면에 공개함으로써 회원이 언제나 용이하게 보실 수 있도록
    조치하고 있습니다.
  </p>
  <p id="ed3c4e25-159d-4dcf-8de2-30679c5419c7" className="">
    개인정보 수집에 대한 동의
  </p>
  <p id="43666054-0192-4bfe-8301-d5e9709b15d9" className="">
    회사는 개인정보를 개인의 동의없이 수집하지 않습니다. 회사의
    개인정보보호정책 또는 이용약관의 내용에 대해 「동의한다」버튼을 클릭할
    수 있는 절차를 마련하여, 「동의한다」버튼을 클릭하면 개인정보 수집에
    대해 동의한 것으로 봅니다.
  </p>
  <p id="6f2e51b9-ac92-42ac-977a-29bae2576fb3" className="">
    수집하는 개인정보의 항목 및 수집방법 ① 수집항목 – 필수항목 : 아이디,
    비밀번호, 이메일, 성명, 연락처, 계좌번호, 은행명 ② 개인정보 수집방법
    홈페이지 회원가입, 전화, 팩스, 서비스의 이용, 이메일, 이벤트 응모,
    생성정보 수집 툴을 통한 수집, 회원정보수정, 고객센터 문의하기, 구독신청,
    제보, 투고
  </p>
  <p id="75fb5b73-d11b-4640-a8d1-0b3b1ab3a8ce" className="">
    개인정보 수집목적 및 이용목적
  </p>
  <p id="89be156c-a6d9-4028-9738-30de7d079324" className="">
    회사는 회원이 자발적으로, 구체적으로 기입할 때만 개인정보를 수집하고
    있습니다. 현재 제공하는 서비스중 개인정보를 수집하는 서비스는 회사의
    독자 회원등록에서 수집하고 있으며 향후 각 서비스별로 개인정보를 추가
    수집할 수도 있습니다. 각각의 경우 수집하는 개인정보는 이름과 E-mail
    ID같은 단순한 내용에서 전화번호 등 구체적인 내용까지 다양합니다. 이 모든
    경우에 필요한 개인정보와 개인정보 사용방법을 명시하고 있습니다. 이는
    회원 본인임이 식별을 요하는 맞춤화된 개인별 서비스이거나 새로운 서비스
    및 이벤트 정보 등을 안내하기 위한 목적을 가지고 있습니다. 이외에 각종
    이벤트에 참여할 경우 서비스 목적상 질문을 통해 개인정보를 수집하고
    있습니다. 이 경우는 본 개인정보 취급 방침과는 별도로 개인정보 수집내용과
    이용방법, 개인정보보호 방법을 명시하고 있습니다.
  </p>
  <p id="48f12526-9f05-4b50-a794-662222e02fcd" className="">
    개인정보 열람, 정정, 삭제
  </p>
  <p id="22efcc66-1b60-4642-9363-167ba640f5b6" className="">
    회원은 언제든지 등록되어 있는 회원의 개인정보를 열람하거나 정정하실 수
    있으며, 아이디(ID) 삭제를 요청하실 수 있습니다. 회원 개인 정보의 열람 및
    정정은 회원정보 수정관리에서 하실 수 있으며, 아이디 삭제는 회원탈퇴를
    클릭하신 다음 정해진 순서에 따라 진행하시면 됩니다.
  </p>
  <p id="59480307-5fad-4871-ab2e-7d3902ba186e" className="">
    개인정보 보유기간 및 이용기간
  </p>
  <p id="c6b474be-139f-4b24-908a-f658792584d1" className="">
    회사는 개인정보의 수집목적 또는 제공받은 목적이 달성된 때에는 귀하의
    개인정보를 지체없이 파기합니다. 다만, 원활한 서비스 제공을 위하여 다음의
    기간 동안 귀하의 개인정보를 보유할 수 있습니다.
  </p>
  <p id="12e502a6-91d3-4e95-bafb-92c89c58d247" className="">
    ① 가입 해지 회원: 가입 해지와 동시에 아이디, 이름을 제외한 모든
    개인정보를 파기하며, 해지일로부터 3개월이 지나면 아이디 외에 모든 정보를
    파기합니다. ② 개인정보 파기 : 전자적 파일 형태로 저장된 개인정보는
    기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.
  </p>
  <p id="e02d9884-252c-4917-8766-55507e1c45b2" className="">
    쿠키에 의한 개인정보 수집
  </p>
  <p id="46a409b1-9ff0-46ca-9444-5691939786ee" className="">
    회사는 회원 개개인에게 개인화되고 맞춤화된 서비스를 제공하기 위해서
    회원의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.
    회사는 쿠키를 이용하여 회원이 방문한 회사 서비스의 방문 및 이용형태를
    파악함으로써 더 유용하고 이용하기 편리한 서비스를 만들어 제공할 수 있게
    됩니다. 또한 회원에게 적합하고 보다 유용한 서비스를 제공하기 위해서
    쿠키를 이용하여 회원의 아이디에 대한 정보를 찾아냅니다. 회사에 접속한 후
    사용하는 서비스나 맞춤화된 서비스를 이용하시기 위해서는 쿠키를
    허용하여야 합니다. 또한 기타 이벤트나 설문조사에서 회원님의 참여 경력을
    확인하기 위해서 쿠키를 이용하게 됩니다. 회원은 쿠키에 대한 선택권을
    가지고 있습니다. 회원의 웹브라우저에서 옵션을 선택함으로써 모든 쿠키를
    허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 모든 쿠키의 저장을
    거부할 수 있습니다. 단, 쿠키의 저장을 거부하실 경우 로그인이 필요한
    회사의 서비스는 이용하실 수 없게 됩니다.
  </p>
  <p id="456ab3b9-d457-42dd-8fef-5ea6d95c3a9e" className="">
    목적외 사용 및 제 3자에 대한 제공
  </p>
  <p id="c33585fa-4a7f-42ac-869c-d7fe08d76ed3" className="">
    회사는 회원 동의 없이 개인정보를 공유하지 않는 것을 원칙으로 하고
    있습니다. 단, 보다 나은 서비스 제공의 필요에 의해 회원의 개인정보를
    제휴사 또는 협력업체에게 제공하거나 또는 제휴사나 협력업체들과 공유할 수
    있습니다. 그럴 경우, &lt;개인정보의 수집목적 및 이용목적&gt;에서 고지한
    범위내에서 사용하며 동 범위를 초과하여 이용하거나 타인 또는 타기업,
    기관에 제공되지 않습니다. 단, 개인정보를 다른 목적으로 사용 할 경우,
    이를 고지하고 서비스를 거부할 수 있는 방법을 이용자에게 제공하며, 적법한
    법적 절차를 밟은 경우, 회사에서 운영하는 서비스와 이용자의 권리와 재산을
    보호를 위해 필요한 경우와 개인의 안전을 보호할 긴박한 경우 이외에는
    개인정보를 공개하지 않습니다. 또한, 이용자 본인이 허락한 경우이거나,
    상거래 등의 이행을 위해 부득이하게 몇 가지 정보가 필요한 경우, 관계법에
    의거, 적법한 절차에 의한 정보기관의 요구가 있을 경우는 예외로 합니다.
    또한, 광고주를 위해 개인정보가 사용되는데 이 경우도 개인의 구체적인
    정보(회원정보 등)가 광고주에게 제공되지는 않습니다. 광고주가 관심을 갖는
    유형에 대한 총체적 정보만 제공됩니다.
  </p>
  <p id="1c6e6769-0418-4099-accd-6b6de21ab1f9" className="">
    개인정보를 위한 기술 및 제도 방침
  </p>
  <p id="6faf8946-2ffe-4bb1-9e59-79da752fd325" className="">
    ① 사용자 정보는 비밀번호에 의해 보호됩니다. 회사에서 특정하게 허락한
    시스템관리자와 본인만이 수정/삭제할 수 있습니다. 따라서 비밀번호를
    제3자에게 알려주어서는 안됩니다. 회사 관계자는 서비스와 관련없이 개인의
    정보나 ID 비밀번호를 전화상이나 E-mail상으로 먼저 요구하지 않습니다.
    공공장소에서 컴퓨터를 사용하거나 타인과 함께 컴퓨터를 공유할 경우 암호가
    자동으로 저장되도록 설정하지 마시고 모든 작업을 완전히 종료하는 등
    개인정보 보호에 필요한 조치를 취하시기 바랍니다. ② 회사의 각 사이트에
    있는 기사나 토론방 서비스를 통해 사용자가 의도적이든 의도적이지 않든
    자발적으로 제공하는 개인정보를 타인이 수집 가공 악용할 수 있다는 점을
    염두에 두시기 바랍니다. 회사에서는 이렇게 공개된 개인정보에 대한 책임은
    지지 않습니다.
  </p>
  <p id="f119582e-8938-4f6a-ac6a-3eab60376d18" className="">
    의견수렴 및 불만처리
  </p>
  <p id="0ccca1b1-b7c0-4882-9580-903229b158b9" className="">
    회사는 개인정보 보호를 위해 최선의 노력을 다하고 있습니다. 만약
    개인정보보호정책에 대한 개선점이나 건의사항에 대해서는 고객센터로 의견
    주시기 바랍니다.
  </p>
  <p id="469752a1-f764-4410-875b-e6098e1ef461" className="">
    개인정보보호를 위한 책임 소재 확인
  </p>
  <p id="ce2bc08b-aeb4-4cf2-ae30-19718de5d7e2" className="">
    이상의 개인정보 보호정책에 열거한 사항 이외의 방법으로 제3자에게
    개인정보가 유출,침해를 당한 사항에 대해서는 회사에서 책임을
    지지않습니다.
  </p>
</div>`,
};

export const termsOfUse = {
  title: "Bitcash 서비스 이용 안내",
  contents: `<div className="page-body">
  <p id="7d6ed331-67fa-44fa-85d1-143a12472d39" className="">이용약관</p>
  <p id="64a45603-5006-4fef-8c10-ddf6818fa585" className="">
    Bitcash 서비스 이용 안내
  </p>
  <p id="0ce8433c-036d-4121-a4d3-f1429bd22c6e" className="">
    제1조 (총칙) Bitcash 서비스(이하”서비스”라 합니다) 이용에 대한 본 동의를
    거부하실 수 있으며, 거부 시에는 회원가입에 제한을 받을 수 있습니다. 비트코인
    등 암호화폐의 경우, 발행 주체를 알 수 없으므로 회원의 소재국 내·외의 어떠한
    제3자(회사 포함)도 가치를 보증하거나 지급을 보증하지 아니합니다. 그러므로
    비트코인 등 암호화폐에는, 회원 소재국의 법률 기타 행정청의 유권해석,
    경제환경 등에 따라 매우 큰 변동성이 있을 수 있고 비트코인 등 암호화폐의
    교환에 대한 법률행위의 해석이 국가별로 상이하여 법정화폐와 다른 특유의
    위험성이 내재되어 있으므로, 회원은 반드시 이 점에 주의하여야 합니다. Bitcash
    서비스의 경우, 회원이 Bitcash 앱을 통해 스테이킹을 활용하여 플랫폼 기축
    암호화폐로 교환할 수 있도록 회사가 제공하는 서비스입니다. 현재 블록체인
    기술은 제3자에 의하여 변조가 불가능한 것으로 평가받고 있으나, 이는 기술의
    발전 또는 기타 예상할 수 없는 사정에 의하여 언제든지 변경될 수 있음을
    인지하시기 바랍니다.
  </p>
  <p id="33ac0d62-5708-4da4-8445-4649e5061953" className="">
    제2조 (개요) 본 이용안내서는 Bitcash가 제공하는 암호화폐 교환 및 Bitcash
    플랫폼의 이용조건 및 절차에 관한 회사와 회원 간의 권리 의무 및 책임사항,
    기타 필요한 사항을 안내합니다.
  </p>
  <p id="c2308066-3831-4d4a-867f-3066c8533b4c" className="">
    제3조 (서비스 이용 및 가입)
  </p>
  <ol
    type="1"
    id="436aebb3-f2a8-4080-ac80-c7e34f20cd91"
    className="numbered-list"
    start="1"
  >
    <li>
      본 이용안내서의 내용은 회사의 “서비스” 회원가입 관련 사이트에 게시하거나
      기타의 방법으로 이용자에게 공지하고, 이용을 원하는 자가 본 이용안내서에
      동의함으로써 회원가입이 성립합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="0f2172f6-8383-4f6c-9c07-4adf8bd70cdc"
    className="numbered-list"
    start="2"
  >
    <li>
      회사는 전 세계 서비스 대상국의 관련 법률을 위배하지 않는 범위에서 본
      이용안내서를 변경할 수 있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="3a387eb2-b736-449a-9e45-0ed7acb563e0"
    className="numbered-list"
    start="3"
  >
    <li>
      회사가 이용안내서를 변경할 경우에는 적용일자 및 변경 사유를 명시하여
      적용일자 7일 이전에 회사 사이트에 공지합니다. 다만, 회원에게 불리하거나
      중대한 내용의 변경의 경우에는 적용일자 30일 이전에 공지합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="071f5d2b-cb5f-4239-bc4f-7e7eda681127"
    className="numbered-list"
    start="4"
  >
    <li>
      회사가 전항에 따라 본 이용안내서의 변경을 공지하면서, 회원에게 일정한 기간
      내에 의사표시를 하지 않으면 의사표시가 표명된 것으로 본다는 뜻을 명확하게
      공지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이
      개정 약관에 동의한 것으로 봅니다.
    </li>
  </ol>
  <ol
    type="1"
    id="4a86e20c-de9d-45d8-bf76-99a6b2117dfc"
    className="numbered-list"
    start="5"
  >
    <li>
      회원이 이용안내서의 변경에 동의하지 않는 경우 회원은 본 “서비스”를 이용할
      수 없습니다.
    </li>
  </ol>
  <p id="aa4e747d-a3ab-4472-8bc9-cb5068671b23" className="">제4조 (세부지침)</p>
  <ol
    type="1"
    id="bd687b5e-d966-44e5-961c-7d471a359f87"
    className="numbered-list"
    start="1"
  >
    <li>
      회사는 “서비스”에 대하여 이용안내서 외에 별도의 운영정책, 세부지침 등(이하
      “세부지침”이라 합니다)을 정하여 이를 회원에게 알리거나 동의를 받을 수
      있으며, 세부지침의 내용이 이 이용안내서와 상충할 경우에는 “세부지침”이
      우선하여 적용됩니다.
    </li>
  </ol>
  <ol
    type="1"
    id="1c4ad93a-11de-4756-b840-70dfc6d7add6"
    className="numbered-list"
    start="2"
  >
    <li>
      이 이용안내서에서 정하지 아니한 사항이나 해석에 대해서는 회사의
      “세부지침”에 따릅니다.
    </li>
  </ol>
  <p id="b0904094-5611-45c3-aaee-152f8cb728b2" className="">
    제5조 (용어의 정의)
  </p>
  <ol
    type="1"
    id="4138d71b-d9fa-4f34-938f-6bf78ef00180"
    className="numbered-list"
    start="1"
  >
    <li>본 이용안내서에서 사용하는 용어의 정의는 다음과 같습니다.</li>
  </ol>
  <ol
    type="1"
    id="4b33b598-e293-4c90-ab34-3c97f3b7c741"
    className="numbered-list"
    start="2"
  >
    <li>
      회원: 이용안내서를 동의하고 회원가입을 하여 회사의 “서비스”를 이용하는
      자를 말합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="92a72cdd-cd64-4047-a79e-25ee7a4af06f"
    className="numbered-list"
    start="3"
  >
    <li>
      회사: 본 플랫폼의 기축 암호화폐인 Bitcoin Cash(BCH)를 발행하는 Bitcash
      FOUND PTE. LTD 을 말합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="6a19ef6d-512d-43fe-b87a-18966901ab2e"
    className="numbered-list"
    start="4"
  >
    <li>
      아이디(ID): 회원의 식별과 서비스 이용을 위하여 회원이 등록하는 ID 를
      말합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="71b140b2-24f2-4438-b1f4-de84098bf79d"
    className="numbered-list"
    start="5"
  >
    <li>
      비밀번호: 회원의 동일성 확인과 회원정보의 보호를 위하여 회원이 설정한 문자
      혹은 숫자 또는 특수문자의 조합을 말합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="d8bca49f-ac90-4cb6-8c39-e2ae84fe3249"
    className="numbered-list"
    start="6"
  >
    <li>
      암호화폐 : 비트코인(BTC), 이더리움(ETH), 테더(USDT), Bitcoin Cash(BCH) 등
      블록체인에서 전자적으로 존재하는 정보로 서비스의 사용 수단을 말합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="37a2d10a-a61e-4929-84bb-2c1c662ccce8"
    className="numbered-list"
    start="7"
  >
    <li>BCH : 회원이 회사의 “서비스”를 이용할 수 있는 암호화폐입니다.</li>
  </ol>
  <p id="0f2dc8ff-13a4-42c0-bfa9-65ba0a9adc40" className="">
    제6조 (서비스 이용 및 제한)
  </p>
  <ol
    type="1"
    id="fe0c5c80-31cc-467e-962d-ead191568271"
    className="numbered-list"
    start="1"
  >
    <li>
      본 ”서비스” 이용을 원하는 자는 회사가 정한 가입 양식에 따라 회원정보를
      기입한 후, 이용안내서에 동의함으로써 회원 가입이 성립합니다. 회원은 본
      이용안내서에 대하여 동의함으로써 회사가 제공하는 서비스 상의 개별 콘텐츠
      이용에 관하여 동의한 것으로 봅니다.
    </li>
  </ol>
  <ol
    type="1"
    id="d2754527-9d04-40ee-8d7d-8ad180956cf5"
    className="numbered-list"
    start="2"
  >
    <li>
      이용 신청자는 복수의 아이디(ID)로 가입 신청할 수 없습니다. 본 “서비스”
      이용계약은 하나의 아이디(ID) 단위로 체결되며 가입이 성립되면 이용 신청자는
      회원으로 등록됩니다.
    </li>
  </ol>
  <ol
    type="1"
    id="12697b57-2fa3-42ea-a5b2-053448538a88"
    className="numbered-list"
    start="3"
  >
    <li>
      회원은 가입한 ID를 타인에게 양도할 수 없고, 누구도 기존 회원의 ID를 양수
      받아 사용할 수 없습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="ece98d8d-cfae-4a3c-af72-6414e16ec684"
    className="numbered-list"
    start="4"
  >
    <li>
      실명이 아니거나 타인의 이름, 전화번호 등의 개인 정보를 도용하여 허위
      가입한 회원은 법적인 보호를 받을 수 없으며, 이에 따른 민사, 형사상의 모든
      책임은 가입한 회원이 져야 합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="7dd325b4-ecc4-4fd4-a7b2-3696610f5731"
    className="numbered-list"
    start="5"
  >
    <li>
      만 19세 미만은 회사에서 제공하는 암호화폐의 채굴 등 일부 서비스의 이용을
      제한합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="5131415d-3e21-40c2-be9a-e56e168e007c"
    className="numbered-list"
    start="6"
  >
    <li>
      본 “서비스” 이용자가 국제자금세탁방지기구(FATF, Financial Action Task
      Force)에서 발표한 국제기준 미이행∙비협조 국가 또는 지역의 국민, 시민권자,
      영주권자, 거주자 등인 경우 서비스 이용이 제한될 수 있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="ee6cc3da-5b81-4bd4-9250-d47582dc2fe5"
    className="numbered-list"
    start="7"
  >
    <li>
      제1항에 따른 신청에 있어 회사는 필요시 관계 법령에 의하여 전문기관을 통한
      실명확인 및 본인인증을 요청할 수 있습니다. 만일, 이러한 회사의 제공 요청을
      거부하여 이용자 본인임이 확인되지 않아 발생하게 되는 불이익에 대하여
      회사는 책임을 지지 않습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="9f340502-bd00-483d-8808-79aa15eb7d03"
    className="numbered-list"
    start="8"
  >
    <li>
      온라인 가입신청 양식에 기재하는 모든 회원 정보는 실제 데이터인 것으로
      간주하며 실명이나 실제 정보를 입력하지 않은 이용자는 법적인 보호를 받을 수
      없으며 “서비스” 이용의 제한을 받을 수 있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="b66dfd5d-df62-4fbc-93b8-e1461db5635b"
    className="numbered-list"
    start="9"
  >
    <li>
      본 “서비스” 이용자가 사실과 다른 정보, 거짓 정보를 기입하거나 추후 그러한
      정보임이 밝혀질 경우 회사는 서비스 이용을 정지할 수 있습니다. 이로 인하여
      회사 또는 제3자에게 발생한 손해는 해당 회원이 모든 책임을 집니다.
    </li>
  </ol>
  <p id="a8766d35-d364-4662-a8d6-4505eeb8e262" className="">
    제7조 (회원정보 사용에 대한 동의)
  </p>
  <ol
    type="1"
    id="9b99e6d3-5918-42bc-bd10-2168e1c4db5d"
    className="numbered-list"
    start="1"
  >
    <li>
      회사는 회원의 개인 정보를 본 “서비스” 이용 및 제공을 위한 목적으로만
      이용합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="a9995091-1185-448f-a892-140e08ab6ddb"
    className="numbered-list"
    start="2"
  >
    <li>
      회원이 회사 및 회사와 제휴한 “서비스”들을 편리하게 이용할 수 있도록 하기
      위해 회원 정보는 회사와 제휴한 업체에 제공될 수 있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="0b6ac037-fbd3-467a-9e48-85f2994c6377"
    className="numbered-list"
    start="3"
  >
    <li>
      회원은 회원정보 수정을 통해 언제든지 개인 정보에 대한 열람 및 수정을 할 수
      있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="cfc8d145-137c-4d4c-a255-bdd5b963613b"
    className="numbered-list"
    start="4"
  >
    <li>
      회원이 이용신청서에 회원정보를 기재하고, 회사에 본 이용안내서에 따라
      이용신청을 하는 것은 회사가 이용신청서에 기재된 회원정보를 수집, 이용 및
      제공하는 것에 동의하는 것으로 간주됩니다.
    </li>
  </ol>
  <p id="362c6f4b-98a6-4e09-9f44-2771143dd676" className="">
    제8조 (서비스 이용의 종료)
  </p>
  <ol
    type="1"
    id="6511ac03-a5da-43a7-8422-1a2cfca0715b"
    className="numbered-list"
    start="1"
  >
    <li>본 “서비스” 이용은 회원의 탈퇴에 의해 종료됩니다.</li>
  </ol>
  <ol
    type="1"
    id="72cc73a5-38be-4ceb-90d6-b52ab8167247"
    className="numbered-list"
    start="2"
  >
    <li>
      회원이 “서비스” 이용을 탈퇴하고자 할 때는 자신의 자산(암호화폐 및 포인트)
      전부를 외부로 이전한 후 회사에 탈퇴 신청을 하여야 합니다. 탈퇴 후 이전되지
      않은 자산에 대해서 회사는 책임지지 않습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="4c5bcb79-47e1-44a3-a535-8b5dca3d8c44"
    className="numbered-list"
    start="3"
  >
    <li>
      회사는 회원이 아래의 사유에 해당하는 경우 상당한 기간을 정하여 최고 후
      “서비스” 이용을 제한하거나 이용을 종료하여 회원을 탈퇴하게 할 수 있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="0cac1661-8333-4204-a77d-e37e6f0718c9"
    className="numbered-list"
    start="4"
  >
    <li>타인의 서비스 ID 및 비밀번호를 도용한 경우</li>
  </ol>
  <ol
    type="1"
    id="9e7c9bec-2c90-4528-b6b2-25fdffa909ef"
    className="numbered-list"
    start="5"
  >
    <li>서비스 운영을 고의로 방해한 경우</li>
  </ol>
  <ol
    type="1"
    id="84e1b362-18ba-4fa1-bb7f-d16e561aa341"
    className="numbered-list"
    start="6"
  >
    <li>가입한 이름이 실명이 아닌 경우</li>
  </ol>
  <ol
    type="1"
    id="c5bd5811-c9ad-41b6-bf82-c714e23fb90b"
    className="numbered-list"
    start="7"
  >
    <li>회원이 사회적 공익을 저해할 목적으로 서비스를 이용하는 경우</li>
  </ol>
  <ol
    type="1"
    id="fcb96ecb-0e1c-4dcb-a0d3-cf7676cd2c4f"
    className="numbered-list"
    start="8"
  >
    <li>타인의 명예를 손상시키거나 불이익을 주는 행위를 한 경우</li>
  </ol>
  <ol
    type="1"
    id="37802b0c-a04d-4614-b911-b040a77987fd"
    className="numbered-list"
    start="9"
  >
    <li>
      서비스의 안정적 운영을 방해할 목적으로 다량의 정보를 전송하거나 광고성
      정보를 전송하는 경우
    </li>
  </ol>
  <ol
    type="1"
    id="d3405cba-e32d-42ac-a345-df7b4be58253"
    className="numbered-list"
    start="10"
  >
    <li>
      정보통신 설비의 오작동이나 정보 등의 파괴를 유발하는 컴퓨터 바이러스
      프로그램 등을 유포하는 경우
    </li>
  </ol>
  <ol
    type="1"
    id="ada16d24-7cd0-4880-ac6e-3e8152056815"
    className="numbered-list"
    start="11"
  >
    <li>타인의 개인 정보, 이용자 ID 및 비밀번호로 부정하게 사용하는 경우</li>
  </ol>
  <ol
    type="1"
    id="ad961e81-9bc1-44f6-a118-57819f368423"
    className="numbered-list"
    start="12"
  >
    <li>
      회사의 서비스 정보를 이용하여 얻은 정보를 회사의 사전 승낙 없이 복제 또는
      유통하거나 상업적으로 이용하는 경우
    </li>
  </ol>
  <ol
    type="1"
    id="674770ae-671f-49c8-830c-5883e2974923"
    className="numbered-list"
    start="13"
  >
    <li>같은 사용자가 다른 ID로 이중등록을 한 경우</li>
  </ol>
  <ol
    type="1"
    id="c173f8df-5f42-4e6e-b632-f354e4571512"
    className="numbered-list"
    start="14"
  >
    <li>
      다른 회원의 ID를 이용하여 사실상 1인이 복수의 ID를 관리하면서 서비스를
      이용하는 경우
    </li>
  </ol>
  <ol
    type="1"
    id="40cbe1a2-159b-44a3-8699-0d8f42e9aaed"
    className="numbered-list"
    start="15"
  >
    <li>
      회사의 개입을 배제할 목적으로 회원 간 거래를 하여 서비스를 이용하게 하는
      경우
    </li>
  </ol>
  <ol
    type="1"
    id="7f40f65a-04e0-47e0-ac57-6fdd9f040f4a"
    className="numbered-list"
    start="16"
  >
    <li>오로지 영리만을 추구할 목적으로 기술적 결함을 악용하는 경우</li>
  </ol>
  <ol
    type="1"
    id="e2df7993-a5db-4c85-bfcb-ed45d10a9cfd"
    className="numbered-list"
    start="17"
  >
    <li>회사, 다른 회원 또는 제3자의 지식재산권을 침해하는 경우</li>
  </ol>
  <ol
    type="1"
    id="3d51de71-88ad-4a1f-9955-67cc5c90132b"
    className="numbered-list"
    start="18"
  >
    <li>
      방송통신심의위원회 등 외부기관의 시정요구가 있거나 불법선거운동과 관련하여
      선거관리위원회의 유권해석을 받은 경우
    </li>
  </ol>
  <ol
    type="1"
    id="06983fdd-797f-4cc7-a0eb-af202d31a02d"
    className="numbered-list"
    start="19"
  >
    <li>이용자에 대한 개인 정보를 그 동의 없이, 수집, 저장, 공개하는 경우</li>
  </ol>
  <ol
    type="1"
    id="ff6cc9fa-670f-486f-90da-8b3290653db3"
    className="numbered-list"
    start="20"
  >
    <li>회원의 서비스 이용이 범죄와 결부된다고 객관적으로 판단되는 경우</li>
  </ol>
  <ol
    type="1"
    id="01d97b5b-567b-4db1-a4f1-35a6ada13043"
    className="numbered-list"
    start="21"
  >
    <li>
      수수료 등 이익을 얻을 목적으로 타인을 대행하여 암호화폐를 거래하는 경우
    </li>
  </ol>
  <ol
    type="1"
    id="e4acfc69-3d40-4e7b-ae0e-f3942924e031"
    className="numbered-list"
    start="22"
  >
    <li>회원의 행위가 기타 관계 법령 및 본 이용안내서에 위배되는 경우</li>
  </ol>
  <ol
    type="1"
    id="91b55077-8598-4236-99c5-d7f43ffe43f7"
    className="numbered-list"
    start="23"
  >
    <li>
      회사는 제2항 내지 제3항에 따라 탈퇴한 회원에게 반환할 암호화폐를 산정하기
      위하여 전수 검수를 실시합니다. 전수 검수 과정에서 발생하는 불가피한 지연에
      관하여 회사는 책임지지 않습니다. 단, 회사에 고의 또는 과실이 있는 경우
      그러하지 아니합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="11d81e06-b34c-4538-870c-c458f2c9e251"
    className="numbered-list"
    start="24"
  >
    <li>
      회사는 제3항에 따라 회원을 탈퇴하게 할 경우, 해당 회원에게 반환할
      암호화폐에서 보상을 환수하고 수수료를 차감합니다.
    </li>
  </ol>
  <p id="0205b6eb-e4f3-428b-9456-69fa980119a3" className="">
    제9조 (회원정보의 변경)
  </p>
  <ol
    type="1"
    id="6977b99f-3ee9-4ac5-8bae-49c34b5b7618"
    className="numbered-list"
    start="1"
  >
    <li>
      회원이 제7조 제3항에 따라 개인 정보를 수정할 경우에도, “서비스” 관리를
      위해 필요한 실명, 아이디 등은 수정할 수 없습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="340f262f-2c14-446e-9cb1-d3963c6ea4fa"
    className="numbered-list"
    start="2"
  >
    <li>
      회원은 회원가입 신청 시 기재한 사항이 변경되었을 경우 온라인으로
      수정하거나 전자우편 기타 방법으로 회사에 대하여 그 변경사항을 알려야
      합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="79bc0b29-4a35-4286-8988-cdcbe7725fec"
    className="numbered-list"
    start="3"
  >
    <li>
      제2항의 변경사항을 회사에 알리지 않아 발생한 불이익에 대하여 회사는
      책임지지 않습니다.
    </li>
  </ol>
  <p id="3f2c23d9-5737-4e83-b8ca-9bf8381f9efd" className="">
    제10조 (회원 정보관리에 대한 회원의 의무)
  </p>
  <ol
    type="1"
    id="17744843-2752-4e1d-945a-bcece2d9f10a"
    className="numbered-list"
    start="1"
  >
    <li>
      아이디와 비밀번호에 관한 모든 관리 책임은 회원에게 있습니다. 회원에게
      부여된 아이디와 비밀번호의 관리 소홀, 부정 사용에 의하여 발생하는 모든
      결과에 대한 책임은 회원에게 있으며, 회사는 이에 대한 책임을 지지 않습니다.
      다만, 회사의 고의 또는 과실로 인한 경우에는 그러하지 아니합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="51697aeb-3f34-421b-8bba-b8bd3aaa8daf"
    className="numbered-list"
    start="2"
  >
    <li>
      회원은 자신의 아이디가 부정하게 사용된 사실을 알게 될 경우 반드시 회사에
      그 사실을 통지하고 회사의 안내에 따라야 합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="42f75638-ad1e-43ad-8e06-c8d7e8478d07"
    className="numbered-list"
    start="3"
  >
    <li>
      제2항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한
      경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지
      않습니다.
    </li>
  </ol>
  <p id="db589db8-5111-4ae7-873f-adbb3aee868d" className="">
    제11조 (정보의 제공) 회사는 전자우편, SNS 등의 방법으로 다음과 같은 서비스에
    대해서 이용 편의를 위한 이용안내 및 상품에 관한 정보를 제공할 수 있습니다.
    이 경우 회원은 언제든지 수신을 거부할 수 있습니다.
  </p>
  <ol
    type="1"
    id="389fd101-e82a-4d8f-ab9b-1680712ae525"
    className="numbered-list"
    start="1"
  >
    <li>암호화폐 거래 관련 서비스</li>
  </ol>
  <ol
    type="1"
    id="b2084195-362f-4d9f-8bf7-62d30b9243ee"
    className="numbered-list"
    start="2"
  >
    <li>이벤트 및 행사 관련 등의 서비스</li>
  </ol>
  <ol
    type="1"
    id="37507415-5832-43ec-8c99-e99ddc603182"
    className="numbered-list"
    start="3"
  >
    <li>기타 회사가 수시로 결정하여 회원에게 제공하는 서비스</li>
  </ol>
  <p id="6843d31b-f6e5-4b3a-a59a-78b7648352f2" className="">
    제12조 (서비스의 종류)
  </p>
  <ol
    type="1"
    id="31b16160-0c30-4383-8cfd-188860eaa595"
    className="numbered-list"
    start="1"
  >
    <li>회사에서 제공하는 서비스에는 암호화폐 스테이킹 등이 있습니다.</li>
  </ol>
  <ol
    type="1"
    id="489454d7-6b5c-47d7-9416-19c44567c208"
    className="numbered-list"
    start="2"
  >
    <li>
      회사가 제공하는 서비스는 금융상품이 아닙니다. 회사는 고정적인 비율의
      보상을 지급할 것을 보증하지 아니합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="65ee8580-a800-44d8-b3ab-ea0697dcbb5e"
    className="numbered-list"
    start="3"
  >
    <li>
      회사가 제공하는 서비스의 종류는 회사의 사정에 의하여 수시로 변경 및 취소될
      수 있으며, 제공되는 서비스에 대한 저작권 및 지식재산권은 회사에
      귀속됩니다.
    </li>
  </ol>
  <ol
    type="1"
    id="48f0df0c-6c50-4f37-ac44-79216f68c68f"
    className="numbered-list"
    start="4"
  >
    <li>
      회사는 서비스와 관련하여 회원에게 회사가 정한 이용조건에 따라 계정,
      아이디, 서비스, 포인트 등을 이용할 수 있는 이용권한만을 부여하며, 회원은
      이를 활용한 유사 서비스 제공 및 상업적 활동을 할 수 없습니다.
    </li>
  </ol>
  <p id="192f7cb5-d5d4-4309-b393-6b6792c1725e" className="">
    제13조 (스테이킹서비스)
  </p>
  <ol
    type="1"
    id="b3fa5e09-46eb-441e-b7ed-acbd903c02f8"
    className="numbered-list"
    start="1"
  >
    <li>
      스테이킹서비스란 특정 조건을 만족시킨 회원으로 하여금 BCH를 리워드 받을 수
      있게 하는 서비스입니다. (회원 입장에서 편의상 해당용어를
      &#x27;스테이킹(staking)&#x27;이라 표현했을 뿐, 실제로는
      &#x27;리워드(Reward)&#x27;를 의미합니다.)
    </li>
  </ol>
  <ol
    type="1"
    id="0d17fd14-fa89-43a6-a13e-ee13886301a2"
    className="numbered-list"
    start="2"
  >
    <li>
      스테이킹을 위한 조건과 채굴량은 BCH의 유통량, 가격, 소비량, 전체 채굴량,
      정책 및 관련 법률 규정에 따라 변할 수 있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="b3574e9a-c1ba-49ff-93a0-0d0859e4b00f"
    className="numbered-list"
    start="3"
  >
    <li>
      BCH(BCH)는 BEP-20으로 발행된 토큰이며, 스테이킹 서비스를 통해 획득한 BCH는
      재단에 의해 사전 발행된 토큰으로 지급됩니다.
    </li>
  </ol>
  <ol
    type="1"
    id="bb8be21e-d14d-48f4-8eda-cea0f2269cc7"
    className="numbered-list"
    start="4"
  >
    <li>
      스테이킹 서비스를 신청하는 과정에서, 회원의 과실로 회사가 정한 절차를
      따르지 않아 발생하는 손해는 회원이 부담하여야 합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="b9bc547b-e941-45b3-b410-66ee5280dd6c"
    className="numbered-list"
    start="5"
  >
    <li>
      스테이킹된 BCH의 가치는 플랫폼 내에서의 가치와 암호화폐 거래소에서의
      가치가 상이할 수 있으며, 회사는 BCH의 암호화폐 거래소에서의 가치를
      보증하지 않습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="a51f0179-d562-485d-82ab-9e8d32446a61"
    className="numbered-list"
    start="6"
  >
    <li>
      스테이킹 서비스는 금융상품이 아니며, 회사는 고정적인 비율의 보상을
      보증하지 않습니다.
    </li>
  </ol>
  <p id="2d32e75b-6873-42e0-8293-f0d0d3fe8d4b" className="">
    제14조 (서비스 내용의 공지 및 변경)
  </p>
  <ol
    type="1"
    id="9835ecb1-6a76-4556-9de9-65fa6cdf8cc2"
    className="numbered-list"
    start="1"
  >
    <li>
      회사는 서비스의 종류에 따라 각 서비스의 특성, 절차 및 방법에 대한 사항을
      서비스 화면을 통하여 공지하며, 회원은 회사가 공지한 각 서비스에 관한
      사항을 이해하고 서비스를 이용해야 합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="c6034839-89fb-46cd-81e3-bbed65db3dc1"
    className="numbered-list"
    start="2"
  >
    <li>
      회사는 상당한 이유가 있는 경우에 운영상, 기술상, 정책상의 필요에 따라
      암호화폐 교환 및 출금방법 변경 등 제공하고 있거나 제공 예정인 개별
      서비스의 전부 또는 일부를 변경할 수 있습니다.
    </li>
  </ol>
  <p id="3c343ec7-9acc-435b-866c-d52d8e39ae54" className="">
    제15조 (서비스의 운영 및 중지)
  </p>
  <ol
    type="1"
    id="1876f752-1501-488c-9bad-fb1165803c8a"
    className="numbered-list"
    start="1"
  >
    <li>
      서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴
      1일 24시간을 원칙으로 합니다. 다만 정기 점검 등의 필요로 회사가 사전에
      고지하여 정한 날이나 시간은 그러하지 않습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="576c024b-f490-423f-ad8e-1c88e6a744cb"
    className="numbered-list"
    start="2"
  >
    <li>
      회사는 서비스를 일정 범위로 분할하여 각 범위별로 이용 가능 시간을 별도로
      정할 수 있습니다. 이 경우 그 내용을 사전에 공지합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="670186ef-5099-4bb7-842a-d8eab10ce940"
    className="numbered-list"
    start="3"
  >
    <li>
      회사는 다음 각 호에 해당하는 경우 서비스 제공을 중지할 수 있습니다. 이
      경우 회사는 불가피한 경우가 아닌 한 서비스 제공 중지를 사전 고지합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="30ba8d53-e42a-4a34-b8ed-7b6340a1c490"
    className="numbered-list"
    start="4"
  >
    <li>서비스용 설비의 보수 등 공사로 인한 부득이한 경우</li>
  </ol>
  <ol
    type="1"
    id="9d93853f-5a4c-4d06-b168-f8bab24d6c67"
    className="numbered-list"
    start="5"
  >
    <li>
      회사가 직접 제공하는 서비스가 아닌 제휴업체 등의 제3자를 이용하여 제공하는
      서비스의 경우 제휴 업체 등의 제3자가 서비스를 중지했을 경우
    </li>
  </ol>
  <ol
    type="1"
    id="057944ce-c7a1-4740-8eda-18a1753d7aa1"
    className="numbered-list"
    start="6"
  >
    <li>기타 불가항력적 사유가 있는 경우</li>
  </ol>
  <ol
    type="1"
    id="19db8b96-3234-4156-b63d-baee1b6858ab"
    className="numbered-list"
    start="7"
  >
    <li>
      회사는 국가비상사태, 정전, 서비스 설비의 장애 또는 서비스 이용의 폭주
      등으로 정상적인 서비스 이용에 지장이 있는 때에는 서비스의 전부 또는 일부를
      제한하거나 정지할 수 있습니다.
    </li>
  </ol>
  <p id="5f6969fb-6c62-44a9-af01-c99bec92b420" className="">
    제16조 (암호화폐 교환 및 전송)
  </p>
  <ol
    type="1"
    id="7a1a6cda-2ed2-4e83-b48a-c48216afcdbb"
    className="numbered-list"
    start="1"
  >
    <li>
      암호화폐를 교환 또는 전송하고자 하는 회원은 회사가 제공하는 방식에 따라
      등록을 하여야 합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="da0a5722-958b-4cc5-953a-9c793520cb6b"
    className="numbered-list"
    start="2"
  >
    <li>
      회사가 회원에게 제공하는 모든 서비스는 각 회원 개인의 편의 증진을 위한
      이용 권한만을 부여하며, 서비스 이용 권한의 범위를 벗어나는 모든 유사
      서비스 제공 및 상업적 활용은 금지됩니다.
    </li>
  </ol>
  <ol
    type="1"
    id="99ad5adb-4cc5-4780-a2a2-c52979b29fa4"
    className="numbered-list"
    start="3"
  >
    <li>
      서비스 제공되는 암호화폐의 종류 및 수수료 등은 암호화폐 시장 변동에 따라
      사전 고지 없이 변경될 수 있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="90c1a06b-2af6-4528-a7e8-cee8bba7557a"
    className="numbered-list"
    start="4"
  >
    <li>
      암호화폐의 교환 및 전송 서비스의 경우 사전 고지 후 일시 혹은 영구적으로
      중단될 수 있습니다.
    </li>
  </ol>
  <p id="147e84d8-fff3-41cf-a299-0a101c92d7c3" className="">
    제17조 (서비스 이용 일시제한)
  </p>
  <ol
    type="1"
    id="4c995650-0fc6-4695-bcf0-3e5bafeadeef"
    className="numbered-list"
    start="1"
  >
    <li>로그인 차단의 경우</li>
  </ol>
  <ol
    type="1"
    id="053ba905-2fda-477d-8802-913bc55dde8e"
    className="numbered-list"
    start="2"
  >
    <li>회원 본인의 요청이 있는 경우</li>
  </ol>
  <ol
    type="1"
    id="1fa3f441-2566-42a9-b2e7-4a991e70cc7c"
    className="numbered-list"
    start="3"
  >
    <li>본인 확인 절차에 실패한 경우</li>
  </ol>
  <ol
    type="1"
    id="74a6bc95-bcd6-418a-a2bb-7370bbf682c1"
    className="numbered-list"
    start="4"
  >
    <li>관할 국가기관 또는 금융기관 등의 요청이 있는 경우</li>
  </ol>
  <ol
    type="1"
    id="7043c295-5f1e-4022-9646-0039d01e60d6"
    className="numbered-list"
    start="5"
  >
    <li>보안비밀번호를 5회 연속 잘못 입력한 경우</li>
  </ol>
  <ol
    type="1"
    id="7caaf26d-9619-4aa9-93ce-3e4cabb9e782"
    className="numbered-list"
    start="6"
  >
    <li>
      사기, 해킹 등의 범죄 또는 전송 사고와 관련 있거나 관련성에 합리적인 의심이
      드는 경우
    </li>
  </ol>
  <ol
    type="1"
    id="765e29e0-23a7-4ac5-9a0f-db6b0c385b52"
    className="numbered-list"
    start="7"
  >
    <li>출금 차단의 경우</li>
  </ol>
  <ol
    type="1"
    id="d9e09f62-84e6-4865-950a-e9bc964f414b"
    className="numbered-list"
    start="8"
  >
    <li>회원이 미성년자인 경우</li>
  </ol>
  <ol
    type="1"
    id="3bff90e5-49b9-4d9e-9ed7-1e0a562b2ad7"
    className="numbered-list"
    start="9"
  >
    <li>회원 본인의 요청이 있는 경우</li>
  </ol>
  <ol
    type="1"
    id="cd9d0ab9-aa25-41b8-a218-40191073fa48"
    className="numbered-list"
    start="10"
  >
    <li>국가기관 또는 금융기관 등의 요청이 있는 경우</li>
  </ol>
  <ol
    type="1"
    id="5a08ca7e-58d5-4636-a5d2-72098837fdb6"
    className="numbered-list"
    start="11"
  >
    <li>
      사기, 해킹 등의 범죄 또는 전송 사고와 관련 있거나 관련성에 합리적인 의심이
      드는 경우
    </li>
  </ol>
  <ol
    type="1"
    id="c8758d7b-f1a4-4d45-aaac-f9ed83274687"
    className="numbered-list"
    start="12"
  >
    <li>비정상적인 경로로 BCH 및 기타 암호화폐를 취득한 자</li>
  </ol>
  <ol
    type="1"
    id="1d5b35bb-a0a0-432e-870c-d1fe91b5e911"
    className="numbered-list"
    start="13"
  >
    <li>BCH 이벤트를 악용하는 행위로 간주되는 경우</li>
  </ol>
  <ol
    type="1"
    id="0d61a58d-90e2-4e2d-b5be-0561964d796c"
    className="numbered-list"
    start="14"
  >
    <li>
      본 약관 제8조 3항에 열거된 행위와 관련 있거나 관련성에 합리적인 의심이
      드는 경우
    </li>
  </ol>
  <ol
    type="1"
    id="2e9d666e-a67c-4a7e-a725-6a5f842aa2b5"
    className="numbered-list"
    start="15"
  >
    <li>
      암호화폐의 급격한 시세 변동에 의해 일시적인 입출금 제한이 필요하다 판단될
      경우 최대 48시간 차단
    </li>
  </ol>
  <p id="1c3e3989-3a5c-42a4-a9ee-5a75e7f205b5" className="">
    제18조 (개인정보의 보호)
  </p>
  <ol
    type="1"
    id="72b89fa0-6ddf-4fed-b804-221d18aae27d"
    className="numbered-list"
    start="1"
  >
    <li>
      회사는 회원의 소재 국가 관계 법령이 정하는 바에 따라 회원의 개인 정보를
      보호하기 위해 노력합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="bb2596d6-086c-406f-a5bb-db2fcb12841a"
    className="numbered-list"
    start="2"
  >
    <li>
      회원의 개인 정보는 서비스의 원활한 제공을 위하여 회원이 동의한 목적과 범위
      내에서만 이용됩니다.
    </li>
  </ol>
  <ol
    type="1"
    id="271deca7-72db-4bae-a8f4-8a847c917845"
    className="numbered-list"
    start="3"
  >
    <li>
      회사는 법령에 의하거나 회원이 별도로 동의하지 아니하는 한, 회원의 개인
      정보를 제3자에게 제공하지 아니하며, 이에 대한 자세한 사항은 개인 정보 처리
      방침에서 정합니다.
    </li>
  </ol>
  <p id="103f8da9-190f-4af1-aa8f-309ad88ef309" className="">
    제19조 (회사의 면책사항 및 손해배상)
  </p>
  <ol
    type="1"
    id="13ecda6e-af5d-48ed-9280-766c50d36c5e"
    className="numbered-list"
    start="1"
  >
    <li>
      회사는 “서비스”와 관련하여 본 이용안내서에 명시되지 않은 어떠한 사항에
      대하여 보증을 하지 않습니다. 또한, 회사는 “서비스”의 사용 수단인
      암호화폐의 가치를 보증하지 않습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="828150de-b76c-48b3-9924-0f620eda5874"
    className="numbered-list"
    start="2"
  >
    <li>
      회사는 천재지변, 디도스(DDoS) 공격, IDC 장애, 서비스 접속의 폭등으로 인한
      서버 다운, 기간 통신 사업자의 회선 장애 등 기타 불가항력적인 사유로
      서비스를 제공할 수 없는 경우에는 서비스 제공에 관한 책임이 면제됩니다.
      다만, 회사의 고의 또는 과실이 있는 경우에는 그러하지 아니합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="ee308305-0c88-4e67-8f5f-80126c39275c"
    className="numbered-list"
    start="3"
  >
    <li>
      회사는 블록체인의 문제, 암호화폐 발행 관리 시스템 자체의 하자 또는 기술적
      문제, 통신서비스 업체의 불량, 정기적인 서버점검 등으로 인하여 불가피하게
      장애가 발생하였을 경우에 책임을 지지 않습니다. 다만, 회사의 고의 또는
      과실에 의한 경우에는 그러하지 아니합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="9e93b05f-91f5-485f-b4b1-968501e0a627"
    className="numbered-list"
    start="4"
  >
    <li>
      회사는 회원의 귀책사유로 인한 서비스 이용 장애나 그 결과에 대하여는 책임을
      지지 않습니다. 다만, 회사의 고의 또는 과실이 있거나 회원에게 정당한 사유가
      있는 경우에는 그러하지 아니합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="749c6eba-31b2-4ebb-9106-a15406fb4894"
    className="numbered-list"
    start="5"
  >
    <li>
      회사는 회원 간 또는 회원과 제3자 상호 간에 서비스를 매개로 하여 교환 등을
      한 경우에는 책임이 면제됩니다. 다만, 회사의 고의 또는 과실이 있는 경우에는
      그러하지 아니합니다.
    </li>
  </ol>
  <ol
    type="1"
    id="b38be8a3-5ec0-4ff7-a438-d5ea730fbeaa"
    className="numbered-list"
    start="6"
  >
    <li>
      회사는 제공되는 모든 “서비스” 이용과 관련하여 회사의 고의 또는 과실이 없는
      한 책임을 지지 않습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="6dbb0e07-b5da-4a8b-8de3-430b0bc76843"
    className="numbered-list"
    start="7"
  >
    <li>
      회사는 안정적인 서비스 제공을 위해 정기적, 비정기적 또는 긴급한 서버
      점검을 실시할 수 있습니다. 만약 서버 점검 완료 후 세계 각국 암호화폐
      거래소의 암호화폐 시세와 서비스 상의 교환 대기 주문의 암호화폐 시세가
      비정상적으로 차이가 날 경우, 회사는 회원을 보호하기 위하여 내부 정책에
      따라 대기 주문 건을 취소할 수 있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="5bbd0119-c75b-4c99-8a83-c176b0cb763c"
    className="numbered-list"
    start="8"
  >
    <li>
      누구든지 서비스 오류, 전산장애 또는 기타 사유로 인하여 제3자 소유의
      암호화폐를 권한 없이 취득하거나 전송받게 될 경우, 회사는 당사자에게 사전
      통지 후 해당 암호화폐를 회수하거나 원상 회복시키는 등 필요한 조치를 취할
      수 있습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="ee0c5ae0-3ba0-4642-8b9b-631b20923f63"
    className="numbered-list"
    start="9"
  >
    <li>
      회사는 회원이 서비스 제공으로부터 기대되는 이익을 얻지 못하였거나 서비스
      제공에 대한 취사선택 또는 이용으로 발생하는 손해 등에 대하여는 책임을 지지
      않습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="7f26ef21-20fd-4a82-8d5a-d286ee6f6e3e"
    className="numbered-list"
    start="10"
  >
    <li>
      회사가 개별 서비스 제공자와 제휴 계약을 맺고 회원에게 개별 서비스를
      제공하는 경우에는 회원이 이 개별 서비스 이용에 동의한 것으로 간주되며 개별
      서비스 제공자의 고의 또는 과실로 인해 회원에게 손해가 발생되는 경우에는
      개별 서비스 제공자가 책임을 집니다.
    </li>
  </ol>
  <p id="8b8475fe-be88-4e22-aeaa-0ff532122ae0" className="">
    제20조 (서비스 부정 사용에 대한 책임) 서비스 부정 사용으로 인하여 발생된
    책임은 전적으로 회원의 책임으로 합니다. 부정 사용은 다음 각 호의 경우에
    해당합니다.
  </p>
  <ol
    type="1"
    id="c414eb67-cf8a-4348-ba0b-ab1d8bffdeb2"
    className="numbered-list"
    start="1"
  >
    <li>
      회원이 접근 매체를 제3자에게 대여하거나 그 사용을 위임한 경우 또는 양도나
      담보의 목적으로 제공한 경우
    </li>
  </ol>
  <ol
    type="1"
    id="a0e7ca65-83a0-4b11-8832-8536028da86a"
    className="numbered-list"
    start="2"
  >
    <li>회원이 고의 또는 과실로 인한 부정 사용의 경우</li>
  </ol>
  <ol
    type="1"
    id="69ccdbc1-af85-49be-9491-b977a9e3ccbb"
    className="numbered-list"
    start="3"
  >
    <li>
      회원이 서비스 부정 사용의 피해 조사를 위한 조사에 협조하지 아니하거나
      방해한 경우
    </li>
  </ol>
  <ol
    type="1"
    id="c7ccbbec-ec46-41cb-a7be-05f6625d95f0"
    className="numbered-list"
    start="4"
  >
    <li>
      회원이 접근매체에 제3자가 권한 없이 접근하였음을 알았음에도 불구하고
      회사에 적시에 알리지 않은 경우
    </li>
  </ol>
  <p id="f6389de4-df33-409f-b2ad-c806f8549cb9" className="">
    제21조 (위험요소) “구매자”는 “토큰”의 구매가 아래 각 호를 포함하나 이에
    한정되지 아니하는 중대한 위험을 수반함을 확인하며, 이에 대하여 “판매자”에
    책임을 묻지 않기로 한다. “구매자”는 “토큰” 구매 전 아래 위험에 대해 신중하게
    검토하여, 필요한 범위 내에서, “토큰” 구매 여부를 결정하기 전, 법률대리인,
    회계사 또는 세무 전문가와 상담하고 “본 계약”을 체결하여야 한다.
  </p>
  <ol
    type="1"
    id="d116d7a5-8732-4908-a039-bcaa09de5cda"
    className="numbered-list"
    start="1"
  >
    <li>
      “토큰”의 가치가 장래에 심각하게 하락하여 “재단”의 지속적인 운영을 위해
      필요한 충분한 자원이 고갈될 가능성이 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="39972076-53d7-4d74-8046-77fe6d2c9973"
    className="numbered-list"
    start="2"
  >
    <li>
      “구매자”의 “구매 이메일”에 접속한 제3자가 “재단” 웹 월렛 상 “구매자”의
      계정에 접속할 가능성이 있다. “구매자”는 “토큰”의 매매와 관련하여 어떠한
      유도신문에도 응답하지 않도록 주의해야 한다.
    </li>
  </ol>
  <ol
    type="1"
    id="d4af3f49-0eef-43fb-ae68-557293088f2a"
    className="numbered-list"
    start="3"
  >
    <li>
      암호화폐는 전 세계 여러 규제당국에 의해 규제감시 대상이 되고 있다.
      “재단”은 하나 이상의 규제당국에 의한 조사 또는 소송에 의해 영향을 받을 수
      있으며, 이는 사업을 계속해서 발전시켜 나갈 “재단”의 능력을 저해하거나
      제한할 수 있고, 규제당국의 정책 등으로 인하여 “토큰” 및 “토큰” 플랫폼,
      “본건 판매”가 불법이 되거나 상업성이 없게 되는 경우 “재단”은 프로젝트의
      개발 또는 서비스의 제공 등을 중단할 수도 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="ab8d4d50-99ab-47bd-aad0-ec720118265d"
    className="numbered-list"
    start="4"
  >
    <li>
      “재단” 이 개발한 계획 중인 사업은 외부의 많은 업체, 개인 또는 기타 조직에
      의해 사용되지 않거나, P2P 생성 및 지분의 유통에 대해 관심을 가진 사람들도
      적을 가능성이 있다. 이러한 관심 부족은 “재단” 및 “토큰”의 잠재적 사용에
      부정적으로 영향을 미칠 수도 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="8dc4a549-b4cb-4c23-a8de-c89d2445c3a6"
    className="numbered-list"
    start="5"
  >
    <li>
      “구매자”는 “재단”이 개발하였거나 개발 중인 토큰 플랫폼이 백서 기재 내용과
      비교하여 일정부분 변경될 수 있음을 인지하고 있다. “구매자”는 “구매자”의
      토큰 플랫폼의 형태 및 기능에 대한 기대가 디자인 및 이행계획 변경 및 해당
      플랫폼의 이행 실행 등 여러 사유로 인해 출시된 토큰과 맞지 않을 수 있음을
      인지하고 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="aa0a4555-43ee-4296-9e8b-a3786ca170bd"
    className="numbered-list"
    start="6"
  >
    <li>
      “판매자”는 임의로 “토큰”을 본 계약과 상이한 가격으로 제3자에게 판매하거나,
      “토큰” 및 “토큰”이 사용될 플랫폼의 마케팅 및 홍보의 목적으로 무상으로
      “토큰”을 제3자에게 분배 및 이에 관한 위탁 등을 할 수 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="a98482dc-3122-4c6e-b71a-c0a6e8ebf615"
    className="numbered-list"
    start="7"
  >
    <li>
      해커, 기타 그룹 또는 조직이 판매 수익인 “토큰”의 절취를 시도할 수 있으며,
      결과적으로 “재단”의 사업발전 역량에 영향을 미칠 수 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="63f2ea5a-fad7-40ee-9bd4-59d1fdbb351e"
    className="numbered-list"
    start="8"
  >
    <li>
      코드 크래킹 또는 퀀텀 컴퓨터의 발전과 같은 기술의 진보가 암호화폐 및
      “재단”에 위험 요소가 될 수 있으며, 이로 인해 “토큰”의 절취 또는 손실이
      발생할 수 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="714add77-708d-44c2-82f2-d6f2d5411061"
    className="numbered-list"
    start="9"
  >
    <li>
      “토큰” 구매 과정에서 절차의 중단 또는 오류가 발생하는 경우, “구매자”는
      “토큰”을 취득하지 못할 수도 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="a940b7cb-10c7-49a4-9a43-e3630c8fc893"
    className="numbered-list"
    start="10"
  >
    <li>
      다른 암호화폐와 마찬가지로, “토큰” 사용을 위한 블록체인은 이중지불
      공격(double-spend attacks), 과반수 채굴능력에 의한 공격(majority mining
      power attacks), ‘이기적 채굴’ 공격(selfish-mining attacks), 및 경쟁조건
      공격(race condition attacks)을 포함하나, 이에 국한되지 않는, 채굴공격에
      취약하다. 공격이 성공하는 경우 “재단”의 “토큰” 플랫폼, 적절한 “토큰” 거래
      진행 및 배열 및 적절한 계약 산정 진행 및 배열에 위험을 초래하게 될 수도
      있다.
    </li>
  </ol>
  <ol
    type="1"
    id="4d56f944-fb0a-4816-aa58-79e1ee8e620d"
    className="numbered-list"
    start="11"
  >
    <li>
      구매자”가 개인 키를 분실하거나 파손하는 경우 이를 되돌릴 수 없다. “재단”이
      “판매자” 또는 “구매자”의 “토큰”과 관련된 개인 키 또는 데이터에 접속을 하지
      못하게 되는 경우 “토큰” 가치에 부정적인 영향을 미칠 수 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="b5e68895-755b-465f-bde6-8f9e72dccb57"
    className="numbered-list"
    start="12"
  >
    <li>
      “토큰”은 새로운 상품이며, 따라서 “토큰”의 가치에 부정적인 영향을 줄 수
      있는 가격 민감성을 가진다. 디지털 자산 산업 개발에 영향을 미치는 요소에는
      다음의 것들이 포함된다.
    </li>
  </ol>
  <ol
    type="1"
    id="e69586f8-42b3-4937-9128-084e55077c6b"
    className="numbered-list"
    start="13"
  >
    <li>
      “토큰” 및 기타 디지털 자산 도입 및 사용에 있어서의 지속적인 세계적 성장
    </li>
  </ol>
  <ol
    type="1"
    id="5b1e8010-4d03-4449-9a09-7b16148eb07a"
    className="numbered-list"
    start="14"
  >
    <li>
      암호화폐 및 기타 디지털 자산 및 그 사용에 대한 정부 및 준정부 기관의
      규제·제한 또는 “토큰” 플랫폼이나 이와 유사한 디지털 자산 시스템에 대한
      접근 및 운영에 대한 규제
    </li>
  </ol>
  <ol
    type="1"
    id="238aed3f-3f6d-430c-b9c8-df14919d5084"
    className="numbered-list"
    start="15"
  >
    <li>“토큰” 플랫폼의 오픈 소스 소프트웨어 프로토콜 유지 및 개발</li>
  </ol>
  <ol
    type="1"
    id="5c48625b-dea3-4789-a12d-f574d81d9f4d"
    className="numbered-list"
    start="16"
  >
    <li>소비자 인구층 및 대중의 취향 및 선호도 변화</li>
  </ol>
  <ol
    type="1"
    id="d822161c-fc56-4220-ba82-f068fa8e9a2f"
    className="numbered-list"
    start="17"
  >
    <li>기타 유사 상품의 가용성 및 인기</li>
  </ol>
  <ol
    type="1"
    id="57ac320d-7f5b-4f4d-9f4c-c02a7e06e572"
    className="numbered-list"
    start="18"
  >
    <li>
      “토큰” 플랫폼 및 디지털 자산과 관련된 일반적인 경제 조건 및 규제 환경
    </li>
  </ol>
  <ol
    type="1"
    id="8a314fc9-308a-490c-8674-e56fe27f30ba"
    className="numbered-list"
    start="19"
  >
    <li>
      제3자는 “토큰” 및 “토큰” 플랫폼과 관련한 디지털 자산 및 그 소스 코드 보유
      및 양도에 관하여 지식재산권을 주장할 수 있으며, 이는 “토큰” 플랫폼 운영에
      부정적인 영향을 미칠 수 있다. 이러한 제3자의 지식재산권 관련 청구는 최종
      소송의 결과와는 관계없이 “토큰”의 장기적인 이용 가능성에 대한 신뢰를
      감소시키거나 최종사용자의 “토큰” 보유 및 양도 능력을 떨어트려, “토큰”의
      가치에 부정적인 영향을 미칠 수 있다. 또한, 지식재산 관련 청구가 인용되는
      경우 “판매자” 및 기타 최종 사용자가 “토큰” 플랫폼에 접근하는 것 또는
      “토큰”을 보유하는 것이 금지될 수 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="b768a68e-1b17-4704-9bc9-7f3c44b29821"
    className="numbered-list"
    start="20"
  >
    <li>
      정치적 또는 경제적 위기는 대규모의 “토큰” 판매를 부추겨 가격의 하락으로
      이어지고 “토큰” 가치에 부정적인 영향을 미칠 수 있다. 상대적으로 새로
      대두된 “토큰” 등 디지털 자산은 대안적이고 탈중앙화된 거래 수단에 대한
      기대에 기초한 공급 및 수요의 역학관계의 영향을 받으며, 해당 공급 및 수요가
      지정학적 사건들에 의해 어떻게 영향을 받을지는 분명하지 않다.
    </li>
  </ol>
  <ol
    type="1"
    id="d265fef3-643c-48bc-a568-e562c8791a9e"
    className="numbered-list"
    start="21"
  >
    <li>
      “판매자”는 “토큰” 가격을 보장하지 않는다. 시장에서 결정되는 “토큰”의
      가격은 “구매자”의 구매 가격보다 낮을 수 있다.
    </li>
  </ol>
  <ol
    type="1"
    id="e1e05f71-2c58-4e34-8ae3-581398b119e6"
    className="numbered-list"
    start="22"
  >
    <li>
      본 계약은 제7조에 의하지 않는 한 해제나 해지가 불가하며, 절취된 또는
      착오로 거래된 “토큰”은 회수가 불가능할 수 있다. 결과적으로 착오로 인한
      “토큰”의 거래는 “토큰” 가치에 부정적인 영향을 미칠 수 있다. “토큰” 거래는
      행정적 관점에서 거래 수취인의 동의 및 적극적인 참여 또는 이론적으로 “토큰”
      플랫폼에서 처리 권한을 가진 과반수의 통제 또는 동의 없이는 되돌릴 수 없다.
      일단 거래가 블록체인에 추가되는 블록상에서 확인 및 기록되면 착오로
      양도되거나 절취된 “토큰”은 일반적으로 되돌릴 수 없으며, “구매자”는 해당
      양도 또는 절취에 대한 어떠한 보상도 받을 수 없다.
    </li>
  </ol>
  <ol
    type="1"
    id="791541db-a561-4d40-975e-d86a77496c6d"
    className="numbered-list"
    start="23"
  >
    <li>
      대중의 관심 부족, 자금 조달 부족, 상업적 성공 가능성 부족, 또는 주요
      인력의 이탈 등이 있는 경우 “토큰” 및 “토큰” 플랫폼의 개발이 중단될 수
      있다.
    </li>
  </ol>
  <p id="ed93c870-9f66-400b-b107-4182a6321d5b" className="">
    제22조 (관리 및 보증의 부인)
  </p>
  <ol
    type="1"
    id="5ea0bacc-f8d1-4df8-860d-98b9758da9d6"
    className="numbered-list"
    start="1"
  >
    <li>
      회사는 회사가 제공하는 서비스를 통하여 이루어지는 회원 간의 교환에
      관련하여 사실 및 진위, 적법성에 대하여 보증하지 않습니다.
    </li>
  </ol>
  <ol
    type="1"
    id="4497bfd7-4840-4cc5-b7b0-df02855c1db9"
    className="numbered-list"
    start="2"
  >
    <li>
      회사에 연결(링크)된 기프티콘몰, 쇼핑몰 등의 결제사이트는 회사와 계약을
      통하여 제휴관계를 맺은 업체들로서 회사는 단지 제휴업체와의 연결(링크)만을
      제공할 뿐이며, 제휴업체의 서비스 운영 및 관리, 물품의 판매, 배송 및 환불
      등에 대한 모든 책임은 전적으로 제휴업체가 부담하고 회사는 일절 관여하지
      않습니다.
    </li>
  </ol>
  <p id="18c27ff1-202a-4a18-a184-ffb212e30f22" className="">
    제23조 (관할법원)
  </p>
  <ol
    type="1"
    id="b020c58f-b751-473b-86f4-83dbbda182c4"
    className="numbered-list"
    start="1"
  >
    <li>
      플랫폼의 서비스 이용과 관련하여 분쟁이 발생될 경우, 플랫폼 제공 주체의
      본사 소재지를 관할하는 법원을 관할 법원으로 합니다.
    </li>
  </ol>
</div>
`,
};

export const signature = {
  title: "전자서명",
  contents: `<body>
  <article id="09ddc6b8-beef-4589-9d98-9cd11c9dbbcf" class="page sans">
    <header>
      <p class="page-description"></p>
    </header>
    <div class="page-body">
      <p id="8b913446-ec31-4179-bc79-08bc6d6fd481" class="">
        당사(Bybis)는 국내 정식 거래소허가 절차를 진행함에 있어
        금융정보분석원(FIU)의 권고사항에 따라 한국 내 기준 불법 영업기간동안
        발생한 투자금 손실전액을 환불 및 보상한다.
      </p>
      <p id="2a931f2e-ea29-4e3c-aa78-20fa59509dec" class="">
        제 1조 (환불일정) 2023년 10월 24일 이내로 결재대금의 절반을 계약자
        명의의 비트코인캐시(BCH) 공식 지갑으로 전송한다.
      </p>
      <p id="e26764b0-d4be-44ba-b675-7651e1e7eb4d" class="">
        제 2조 (손실보상) 반액 환불을 받은 후 6개월 이내 비트코인캐시를 시세대비
        3분의 1의 가격으로 매수할 수 있는 프라이빗 세일 참여권을 부여받는다.
      </p>
      <p id="c69a320b-430e-41aa-9a15-9ad0a15400fe" class="">
        제 3조 (협의이행) 시세 차익을 통해 결제대금 전액에 준하는 환불과 보상을
        이행 받았음을 확인 후 환불과 보상을 받았음을 확인하는 확인서에 서명할
        것을 약속합니다.
      </p>
      <p id="067ffa57-7c13-40ca-bf2e-a8e77a07c911" class="">
        계약의 성립을 증명하기 위하여 해당 계약서를 각각 서명 후 저장하여
        보관하면 위의 내용이 제대로 이행되지 않을 시 모든 법적 책임을 받을 것을
        명시합니다.
      </p>
      <p id="447b6339-2183-49a7-a577-a664a32007a1" class=""></p>
    </div>
  </article>
  <span class="sans" style="font-size: 14px; padding-top: 2em"></span>
</body>
`,
};
