import React, { useEffect, useState } from 'react';
import { useRecoilValue } from 'recoil';
import styled from 'styled-components';
import { noListImg } from '_/assets';
import {
  Background,
  Text,
  Header,
  LargeCard,
  FixedBottomNav,
} from '_/components';
import { colors } from '_/constants';
import { useApiDepositList, useLoading } from '_/hooks';
import { userState } from '_/recoil';
import { IDeposit } from '_/types';

const DepositList = () => {
  const { renderLoading } = useLoading();
  const userInfo = useRecoilValue(userState);
  const {
    data: depositListData,
    isSuccess,
    isLoading,
  } = useApiDepositList(userInfo._id);
  const [depositList, setDepositList] = useState<IDeposit[]>();

  useEffect(() => {
    renderLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    if (!isSuccess) {
      return;
    }

    setDepositList(depositListData.data);
  }, [depositListData]);

  if (!depositList) return null;
  return (
    <>
      <Header />
      <div className="layout">
        <StyledWrap>
          <Text text="입금 내역 확인" />
          <StyledCardWrap>
            {depositList.length ? (
              depositList.map((deposit: IDeposit) => <LargeCard {...deposit} />)
            ) : (
              <StyledNoResult>
                <img src={noListImg} />
                <StyledText>입금 내역이 없습니다.</StyledText>
              </StyledNoResult>
            )}
          </StyledCardWrap>
        </StyledWrap>
      </div>
      <FixedBottomNav />
      <Background />
    </>
  );
};

export default DepositList;

const StyledWrap = styled.div`
  margin: 36px 0 110px;
`;

const StyledCardWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const StyledNoResult = styled.div`
  width: 100%;
  padding: 84px 67px 0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 21px;
`;

const StyledText = styled.p`
  color: ${colors.grey06};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.078px;
`;
