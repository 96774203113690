import React from 'react';

const Left = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="13"
      height="14"
      viewBox="0 0 13 14"
      fill="none"
    >
      <path
        d="M2 7L1.29289 7.70711L0.585788 7L1.29289 6.29289L2 7ZM12 6C12.5523 6 13 6.44772 13 7C13 7.55229 12.5523 8 12 8L12 6ZM7.29289 13.7071L1.29289 7.70711L2.70711 6.29289L8.70711 12.2929L7.29289 13.7071ZM1.29289 6.29289L7.2929 0.292894L8.70711 1.70711L2.70711 7.70711L1.29289 6.29289ZM2 6L12 6L12 8L2 8L2 6Z"
        fill="white"
      />
    </svg>
  );
};

export default Left;
