import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { loadingState } from '_/recoil';

export const useLoading = () => {
  const [loading, setLoading] = useRecoilState(loadingState);
  const endLoading = useResetRecoilState(loadingState);

  const renderLoading = useCallback(
    (isOpen: boolean) => {
      setLoading({
        isOpen,
      });
    },
    [setLoading],
  );

  return {
    renderLoading,
    loading,
  };
};
