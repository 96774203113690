import React from 'react';
import styled from 'styled-components';
import Badge from '../Badge';
import { colors } from '_/constants';
import { IWithdrawItem, IDeposit } from '_/types';
import { convertDate } from '_/utils';

const LargeCard = ({
  // _id,
  date,
  dollar,
  // withdraw (optionalData)
  accountNumber,
  status,
  // deposit (optionalData)
  titleBitcoinCash,
  titleWon,
  reason,
  won,
}: IWithdrawItem & IDeposit) => {
  const getTitle = () => {
    if (titleWon) {
      return titleWon;
    }

    return titleBitcoinCash || '<></>';
  };

  return (
    <StyledWrap>
      <StyledFlexWrap>
        <StyledDateText>{convertDate(date)}</StyledDateText>
      </StyledFlexWrap>
      <div>
        <StyledPriceWrap>
          <StyledTitleWrap>
            <StyledTitle
              dangerouslySetInnerHTML={{
                __html: getTitle(),
              }}
            />
          </StyledTitleWrap>
          <Badge badgeType={status ? status : reason} />
        </StyledPriceWrap>
        <StyledText>
          {won && <span className="won">₩ {won}</span>}
          <span>$ {dollar}</span>
        </StyledText>
      </div>

      {!!accountNumber && (
        <StyledAccountWrap>
          <StyledAccountText>계좌 번호</StyledAccountText>
          <StyledAccountNum>{accountNumber}</StyledAccountNum>
        </StyledAccountWrap>
      )}
    </StyledWrap>
  );
};

export default LargeCard;

const StyledWrap = styled.div`
  padding: 14px 16px 18px;
  border-radius: 20px;
  border: 0.5px solid ${colors.grey01};
  background: ${colors.white};
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: column;
  gap: 9px;
`;

const StyledPriceWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 72px;
`;

const StyledFlexWrap = styled.div`
  display: flex;
  gap: 6px;
  align-items: center;
`;

const StyledDateText = styled.p`
  color: ${colors.grey07};
  font-size: 13px;
  font-weight: 700;
  line-height: 16px;
  letter-spacing: -0.33px;
`;

const StyledTitleWrap = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.h1`
  color: ${colors.grey12};
  font-size: 24px;
  font-weight: 700;
  line-height: 30px;
  letter-spacing: 0.36px;

  span {
    margin-left: 6px;
    color: ${colors.grey12};
    font-size: 16px;
    font-weight: 600;
    line-height: 21px;
    letter-spacing: -0.32px;
  }
`;

const StyledText = styled.p`
  margin-top: 5px;
  color: ${colors.grey05};
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;

  span[class='won'] {
    &::after {
      content: '•';
      margin: 0 3px;
      color: ${colors.grey03};
    }
  }
`;

const StyledAccountWrap = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2px;
  border-top: 1px solid ${colors.grey01};
  margin-top: 3px;
`;

const StyledAccountText = styled.p`
  color: ${colors.grey07};
  font-size: 13px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.078px;
  margin-top: 12px;
`;

const StyledAccountNum = styled.p`
  color: ${colors.grey12};
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.078px;
`;
