import { useQuery } from '@tanstack/react-query';
import { ExternalResponse, IPointList, UserIdType } from '_/types';
import { fetchApi, generateUrl } from './basicApi';

const POINTS_STATE_KEY = 'bitcash/POINTS';

export const useApiPoints = (userId: UserIdType) => {
  const url = generateUrl(`/v1/users/${userId}/wallets`);

  const fetchPoints = (): Promise<ExternalResponse<IPointList>> =>
    fetchApi(url);

  return useQuery({
    queryKey: [POINTS_STATE_KEY, userId],
    queryFn: fetchPoints,
    enabled: !!userId,
    retry: 3,
  });
};
