import React from 'react';
import styled from 'styled-components';
import { IDepositStatus, IWithdrawStatus } from '_/types';

type TBadge = IWithdrawStatus | IDepositStatus | 'DEFAULT';
interface IBadge {
  badgeType?: TBadge;
}
interface IColor {
  text: string;
  textColor: string;
  border: string;
  bg: string;
}

const Badge = ({ badgeType }: IBadge) => {
  const getColor = (type: TBadge): IColor => {
    switch (type) {
      case 'DEFAULT':
        return {
          text: 'BCH',
          textColor: '#00D24C',
          border: 'rgba(0, 210, 76, 0.05)',
          bg: '#def9e8',
        };
      case 'DONE':
        return {
          text: '완료됨',
          textColor: '#00D24C',
          border: 'rgba(0, 210, 76, 0.05)',
          bg: '#def9e8',
        };
      case 'INPROGRESS':
        return {
          text: '진행중',
          textColor: '#F39F20',
          border: 'rgba(243, 159, 32, 0.05)',
          bg: '#FFF0DA',
        };
      case 'CANCELED':
        return {
          text: '취소',
          textColor: '#FF4F4F',
          border: 'rgba(255, 79, 79, 0.05)',
          bg: '#FFE8E8',
        };
      case 'INTEREST':
        return {
          text: '이자',
          textColor: '#00D24C',
          border: 'rgba(0, 210, 76, 0.05)',
          bg: '#def9e8',
        };
      case 'DEPOSIT':
        return {
          text: '입금',
          textColor: '#FF6712',
          border: 'rgba(255, 103, 18, 0.05)',
          bg: '#FFEADE',
        };
      case 'REFERRAL':
        return {
          text: '추천인',
          textColor: '#0087D2',
          border: 'rgba(0, 135, 210, 0.05)',
          bg: '#DFF2FD',
        };

      default:
        return {
          text: 'BCH',
          textColor: '#00D24C',
          border: 'rgba(0, 210, 76, 0.05)',
          bg: '#def9e8',
        };
    }
  };

  if (!badgeType) return null;
  const { text, textColor, border, bg } = getColor(badgeType);
  return (
    <StyledWrap $textColor={textColor} $border={border} $bg={bg}>
      <StyledText>{text}</StyledText>
    </StyledWrap>
  );
};

export default Badge;

const StyledWrap = styled.div<{
  $textColor: string;
  $border: string;
  $bg: string;
}>`
  display: flex;
  padding: 2px 10px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  gap: 10px;
  border-radius: 50px;
  border: ${({ $border }) => `0.5px solid ${$border}`};
  color: ${({ $textColor }) => $textColor};
  background: ${({ $bg }) => $bg};
`;

const StyledText = styled.div`
  text-align: center;
  font-size: 11px;
  font-weight: 700;
  line-height: 16px;
`;
