import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { CheckBox, FixedBottomBtn, Input, Navbar } from '_/components';
import { colors, RegisterValidation } from '_/constants';
import {
  useApiCheckDuplicated,
  useApiSignUp,
  useCheckBoxPopup,
  useLoading,
  usePopup,
} from '_/hooks';
import { IMutateStatus, IQueryStatus, ISignUpReq } from '_/types';

interface ISignUp extends ISignUpReq {
  privacyChecked: boolean;
  termsOfUseChecked: boolean;
}

const SignUp = () => {
  const navigate = useNavigate();
  const { register, handleSubmit, formState, watch } = useForm<ISignUp>();
  const { errors } = formState;

  const { openCheckBoxPopup } = useCheckBoxPopup();
  const { openPopup } = usePopup();
  const { renderLoading } = useLoading();

  const [isPasswordSame, setIsPasswordSame] = useState<boolean>(false);
  const [isFillAllInput, setIsFillAllInput] = useState<boolean>(false);
  const [isPossibleSubmit, setIsPossibleSubmit] = useState<boolean>(false);

  const [idValue, setIdValue] = useState<string>();
  const { status: idCheckStatus, isLoading } = useApiCheckDuplicated(idValue);
  const {
    status: signUpStatus,
    mutate: mutateSignUp,
    isPending,
  } = useApiSignUp();

  useEffect(() => {
    renderLoading(isPending || isLoading);
  }, [isPending, isLoading]);

  useEffect(() => {
    const subscription = watch((inputObj) => {
      setIsPasswordSame(inputObj.password === inputObj.passwordCheck);

      let isFill = true;
      const inputArr = Object.values(inputObj) as Array<string>;

      // 1. Empty value check
      if (inputArr.slice(0, 7).includes('')) {
        isFill = false;
      }

      // 2. Checkbox validation
      if (!inputArr.slice(8, 10).every((checked) => checked)) {
        isFill = false;
      }

      setIsFillAllInput(isFill);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    setIsPossibleSubmit(
      isFillAllInput &&
        (!formState.errors || !Object.keys(formState.errors).length),
    );
  }, [formState, isFillAllInput]);

  useEffect(() => {
    handleCheckDuplicateID(idCheckStatus);
  }, [idCheckStatus]);

  useEffect(() => {
    handleSignUp(signUpStatus);
  }, [signUpStatus]);

  const handleSubmitBtn: SubmitHandler<ISignUp> = (data) => {
    const { privacyChecked, termsOfUseChecked, ...signUpData } = data;
    mutateSignUp(signUpData);
  };

  const handleClickDuplicateCheckBtn = () => {
    const idValue = watch('id');
    if (!idValue) {
      openPopup('아이디를 입력해주세요.');
      return;
    }

    setIdValue(idValue);
  };

  const handleCheckDuplicateID = (status: IQueryStatus) => {
    switch (status) {
      case 'success':
        openPopup('이미 존재하는 아이디 입니다.');
        return;
      case 'error':
        openPopup('중복 확인이 완료되었습니다.');
        return;
    }
  };

  const handleSignUp = (status: IMutateStatus) => {
    switch (status) {
      case 'success':
        openPopup('회원가입이 완료되었습니다!', () => navigate('/'));
        return;
      case 'error':
        openPopup('오류가 발생했습니다');
        return;
    }
  };

  return (
    <>
      <Navbar title="회원가입" />
      <StyledLayout>
        <StyledWrap>
          <StyledInputWrap>
            <Input
              placeholderText="아이디 입력"
              type="text"
              labelText="ID"
              isRequired={true}
              disabled={idCheckStatus === 'error'}
              buttonProps={{
                text: '중복확인',
                sizeType: 'small',
                disabled:
                  !watch('id')?.length ||
                  !!errors.id ||
                  idCheckStatus === 'error',
                onClick: handleClickDuplicateCheckBtn,
              }}
              warningText={
                errors.id
                  ? errors.id.message
                  : '5-20자, 영문자와 숫자의 조합으로 입력해주세요.'
              }
              {...register('id', {
                required: '아이디는 필수 입력입니다.',
                pattern: RegisterValidation.id,
              })}
            />
            <Input
              placeholderText="이름 입력"
              type="text"
              labelText="이름"
              isRequired={true}
              warningText={errors.name && errors.name.message}
              {...register('name', {
                required: '이름은 필수 입력입니다.',
              })}
            />
            <Input
              placeholderText="휴대폰 번호 입력 ('-' 제외하여 입력)"
              type="text"
              labelText="휴대폰 번호"
              isRequired={true}
              warningText={errors.phoneNumber && errors.phoneNumber.message}
              {...register('phoneNumber', {
                required: '휴대폰 번호는 필수 입력입니다.',
                pattern: RegisterValidation.phone,
              })}
            />
            <Input
              placeholderText="이메일 입력"
              type="text"
              labelText="이메일"
              isRequired={true}
              warningText={errors.email && errors.email.message}
              {...register('email', {
                required: '이메일은 필수 입력입니다.',
                pattern: RegisterValidation.email,
              })}
            />
            <Input
              placeholderText="비밀번호"
              type="password"
              labelText="비밀번호 입력"
              isRequired={true}
              warningText={errors.password && errors.password.message}
              {...register('password', {
                required: '비밀번호는 필수 입력입니다.',
                pattern: RegisterValidation.password,
              })}
            />
            <Input
              placeholderText="비밀번호 확인"
              type="password"
              labelText="비밀번호 확인"
              isRequired={true}
              warningText={
                !isPasswordSame
                  ? '비밀번호가 일치하지 않습니다.'
                  : errors.passwordCheck && errors.passwordCheck.message
              }
              {...register('passwordCheck', {
                required: '비밀번호는 필수 입력입니다.',
                pattern: RegisterValidation.password,
              })}
            />
            <Input
              placeholderText="지갑 비밀번호"
              type="password"
              labelText="지갑 비밀번호 입력"
              isRequired={true}
              warningText={
                errors.walletPassword
                  ? errors.walletPassword.message
                  : '*지갑 비밀번호는 숫자로 4자리이며, 암호화 코드로 저장되므로 당사는 보관하지 않아 분실시 찾기가 불가능합니다.'
              }
              {...register('walletPassword', {
                required: '지갑 비밀번호는 필수 입력입니다.',
                pattern: RegisterValidation.walletPassword,
              })}
            />
            <Input
              placeholderText="휴대폰 번호 입력 ('-' 제외하여 입력)"
              type="text"
              labelText="추천인 전화번호"
              isOptional={true}
              warningText={
                errors.referralPhoneNumber && errors.referralPhoneNumber.message
              }
              {...register('referralPhoneNumber', {
                pattern: RegisterValidation.phone,
              })}
            />
          </StyledInputWrap>
          <StyledAgreeWrap>
            <StyledCheckHeadLine>서비스 신청 및 약관 동의</StyledCheckHeadLine>
            <StyledCheckWrap>
              <CheckBox
                text="개인정보 취급방침 동의하기"
                {...register('privacyChecked', {
                  onChange: () => {
                    if (watch('privacyChecked')) {
                      openCheckBoxPopup('privacy');
                    }
                  },
                })}
                isChecked={watch('privacyChecked')}
              />
              <CheckBox
                text="이용약관 동의하기"
                {...register('termsOfUseChecked', {
                  onChange: () => {
                    if (watch('termsOfUseChecked')) {
                      openCheckBoxPopup('termsOfUse');
                    }
                  },
                })}
                isChecked={watch('termsOfUseChecked')}
              />
            </StyledCheckWrap>
          </StyledAgreeWrap>
        </StyledWrap>
      </StyledLayout>
      <FixedBottomBtn
        type="submit"
        text="회원가입"
        disabled={isPending || !isPossibleSubmit || !isPasswordSame}
        onClick={handleSubmit(handleSubmitBtn)}
      />
    </>
  );
};

export default SignUp;

const StyledLayout = styled.div`
  max-width: 575px;
  margin: 0 auto;
`;

const StyledWrap = styled.div`
  margin: 74px 0 150px 0;
  display: flex;
  flex-direction: column;
  gap: 44px;
`;

const StyledInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  padding: 0 16px;
  box-sizing: border-box;
`;

const StyledAgreeWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  padding: 44px 16px 0;
  box-sizing: border-box;
  border-top: 10px solid ${colors.grey01};
`;

const StyledCheckHeadLine = styled.h1`
  color: ${colors.grey12};
  font-size: 17px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  letter-spacing: -0.408px;
`;

const StyledCheckWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
