import React from 'react';

const Line_white = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="2"
      height="14"
      viewBox="0 0 2 14"
      fill="none"
    >
      <path d="M1 0L1 14" stroke="white" strokeWidth="2" />
    </svg>
  );
};

export default Line_white;
