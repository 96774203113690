import React, { useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Input, Navbar } from '_/components';
import { EmailValidation, colors } from '_/constants';
import { useApiAccountFind, useLoading, usePopup } from '_/hooks';
import { IAccountFindReq, IMutateStatus } from '_/types';

const AccountFind = () => {
  const navigate = useNavigate();
  const { openPopup } = usePopup();
  const { renderLoading } = useLoading();
  const { register, handleSubmit, formState } = useForm<IAccountFindReq>();
  const { errors } = formState;
  const { mutate, status, isPending } = useApiAccountFind();

  useEffect(() => {
    renderLoading(isPending);
  }, [isPending]);

  useEffect(() => {
    handleAccountFind(status);
  }, [status]);

  const handleSubmitBtn: SubmitHandler<IAccountFindReq> = (data) => {
    mutate(data);
  };

  const handleAccountFind = (status: IMutateStatus) => {
    switch (status) {
      case 'success':
        openPopup('등록하신 메일 주소로 계정 정보가 발송되었습니다.', () =>
          navigate('/'),
        );
        return;
      case 'error':
        openPopup('존재하지 않는 계정입니다.');
        return;
    }
  };

  return (
    <>
      <Navbar title="ID/Password 찾기" />
      <div className="layout">
        <StyledWrap>
          <StyledInner>
            <StyledTitleWrap>
              <StyledHeadLine>
                가입시 입력하신 정보를
                <br />
                입력해주세요
              </StyledHeadLine>
              <StyledText>
                회원가입 시 등록하신 이메일 주소를 입력해주세요
                <br />
                해당 이메일로 아이디와 비밀번호 정보를 보내드립니다.
              </StyledText>
            </StyledTitleWrap>
          </StyledInner>
          <StyledBottomForm onSubmit={handleSubmit(handleSubmitBtn)}>
            <StyledInputWrap>
              <Input
                placeholderText="이름 입력"
                type="text"
                warningText={errors.name && errors.name.message}
                {...register('name', {
                  required: '이름은 필수 입력입니다.',
                })}
              />
              <Input
                placeholderText="이메일 입력"
                type="text"
                warningText={errors.email && errors.email.message}
                {...register('email', {
                  required: '이메일은 필수 입력입니다.',
                  pattern: EmailValidation.email,
                })}
              />
            </StyledInputWrap>
            <Button text="확인" type="submit" disabled={isPending} />
          </StyledBottomForm>
        </StyledWrap>
      </div>
    </>
  );
};

export default AccountFind;

const StyledWrap = styled.div`
  margin: 90px 0 130px 0;
  display: flex;
  flex-direction: column;
  gap: 26px;
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StyledTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const StyledHeadLine = styled.h1`
  color: ${colors.grey12};
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
`;

const StyledText = styled.p`
  color: ${colors.grey07};
  font-size: 13px;
  font-weight: 400px;
  line-height: 19px;
`;

const StyledBottomForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 14px;
`;
