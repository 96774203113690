import { atom } from 'recoil';

interface ILoadingState {
  isOpen: boolean;
}

export const loadingState = atom<ILoadingState>({
  key: 'loadingState',
  default: {
    isOpen: false,
  },
});
