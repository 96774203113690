import React from 'react';

const LockUp_fill = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="26"
      height="26"
      viewBox="0 0 26 26"
      fill="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9999 2C12.8999 2 10.2999 2.9 8.8999 4.1C11.1999 4.9 12.8999 7.1 12.9999 9.7C13.9999 9.9 14.9999 10 15.9999 10C18.0999 10 20.1999 9.6 21.6999 8.8C23.1999 8.1 23.9999 7.1 23.9999 6C23.9999 3.8 20.3999 2 15.9999 2Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5999 10.6C20.6999 11.5 18.3999 12 15.9999 12C15.4999 12 14.8999 12 14.3999 11.9C14.4999 12.3 14.5999 12.7 14.6999 13.2C14.7999 13.9 14.7999 14.7 14.7999 15.6V15.9C15.1999 15.9 15.6999 16 16.0999 16C18.1999 16 20.2999 15.6 21.7999 14.8C23.2999 14 24.0999 13 24.0999 12V9.69995C23.4999 10.1 23.0999 10.4 22.5999 10.6Z"
        fill="#1C1C1E"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.5999 16.6C20.6999 17.5 18.3999 18 15.9999 18C15.5999 18 15.0999 18 14.6999 17.9C14.6999 18.1 14.6999 18.4 14.6999 18.6C14.5999 19.4 14.4999 20.1 14.0999 20.8C13.8999 21.2 13.6999 21.5 13.3999 21.8C14.1999 21.9 15.0999 22 15.9999 22C20.3999 22 23.9999 20.2 23.9999 18V15.7C23.4999 16.1 23.0999 16.4 22.5999 16.6Z"
        fill="#1C1C1E"
      />
      <path
        d="M11.4002 10.8C11.4002 10.8 11.4002 10.7 11.4002 10.6V9.8C11.4002 7.5 9.5002 5.5 7.1002 5.5H7.0002H6.9002C4.5002 5.5 2.6002 7.5 2.6002 9.8V10.6C2.6002 10.7 2.6002 10.8 2.6002 10.8C1.5002 11.3 0.700195 12.4 0.700195 13.7V18.5C0.700195 20.3 2.0002 21.8 3.8002 21.8H6.7002H7.0002H7.3002H10.2002C12.0002 21.8 13.3002 20.3 13.3002 18.5V13.7C13.3002 12.4 12.5002 11.3 11.4002 10.8ZM7.0002 17.9C6.2002 17.9 5.5002 17.2 5.5002 16.4C5.5002 15.6 6.2002 14.9 7.0002 14.9C7.8002 14.9 8.5002 15.6 8.5002 16.4C8.5002 17.2 7.8002 17.9 7.0002 17.9ZM9.5002 10.6C9.5002 10.7 9.5002 10.7 9.5002 10.7H7.3002H7.0002H6.7002H4.5002C4.5002 10.7 4.5002 10.7 4.5002 10.6V9.8C4.5002 8.5 5.6002 7.4 6.9002 7.4H7.0002H7.1002C8.4002 7.4 9.5002 8.5 9.5002 9.8V10.6Z"
        fill="#1C1C1E"
      />
    </svg>
  );
};

export default LockUp_fill;
