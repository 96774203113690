import { useCallback } from 'react';
import { useRecoilState, useResetRecoilState } from 'recoil';
import { checkBoxPopupState } from '_/recoil';
import { ICheckBoxStatus } from '_/types';

export const useCheckBoxPopup = () => {
  const [checkBoxPopup, setCheckBoxPopup] = useRecoilState(checkBoxPopupState);
  const resetCheckBoxPopup = useResetRecoilState(checkBoxPopupState);

  const openCheckBoxPopup = useCallback(
    (type: ICheckBoxStatus, onClick?: () => void) => {
      setCheckBoxPopup({
        isOpen: true,
        type,
        onClick,
      });
    },
    [setCheckBoxPopup],
  );

  return {
    openCheckBoxPopup,
    resetCheckBoxPopup,
    checkBoxPopup,
  };
};
