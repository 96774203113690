import React from 'react';

const NotChecked = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
    >
      <rect
        x="0.75"
        y="0.75"
        width="26.5"
        height="26.5"
        rx="13.25"
        stroke="#E5E5EA"
        strokeWidth="1.5"
      />
      <path
        d="M8 12.8571L12.8 18L20 9"
        stroke="#E5E5EA"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NotChecked;
