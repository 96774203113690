import { useQuery } from '@tanstack/react-query';
import { ExternalResponse, IDeposit, UserIdType } from '_/types';
import { fetchApi, generateUrl } from './basicApi';

const CHECK_ISAGREE_SIGNATURE_STATE_KEY = 'bitcash/CHECK_ISAGREE_SIGNATURE';

export const useApiCheckAgreeSignature = (userId: UserIdType) => {
  const url = generateUrl(`/v1/users/${userId}/agreementElectronicContract`);

  const fetchCheck = (): Promise<ExternalResponse<IDeposit[]>> => fetchApi(url);

  return useQuery({
    queryKey: [CHECK_ISAGREE_SIGNATURE_STATE_KEY, userId],
    queryFn: fetchCheck,
    enabled: !!userId,
    retry: false,
  });
};
