import axios, { AxiosError } from 'axios';
import Cookies from 'js-cookie';
import { ACCESS_TOKEN } from '_/constants';

const basicAxios = axios.create({
  timeout: 10000,
  headers: {
    'Content-Type': 'application/json',
  },
});

basicAxios.interceptors.request.use(
  (config) => {
    const accessToken = Cookies.get(ACCESS_TOKEN);
    if (!accessToken) {
      config.headers.Authorization = null;
      return config;
    }

    if (config.headers && accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
      return config;
    }

    console.log('request start', config);
  },
  (error) => {
    console.error('request error', error);
    return Promise.reject(error);
  },
);

basicAxios.interceptors.response.use(
  (res) => {
    return res;
  },
  (error: AxiosError) => {
    // Not Authorization
    if (error.response?.status === 401) {
      Cookies.remove(ACCESS_TOKEN);
      alert('로그아웃 되었습니다.');
      window.location.href = '/';
    }

    return Promise.reject(error);
  },
);

export type QueryStringParams = {
  [key: string]: string | number | boolean | undefined;
};

// generate func
export function generateQueryString(params: QueryStringParams = {}) {
  return Object.keys(params)
    .map((key) => `${key}=${encodeURIComponent(String(params[key]))}`)
    .join('&');
}

export const generateUrl = (
  path: string,
  params = {},
  prefix = process.env.REACT_APP_API_URL,
) => {
  const query = generateQueryString(params);
  return `${prefix}${path}${query ? `?${query}` : ''}`;
};

// fetch, post func
export const fetchApi = async (url: string, option?: any) => {
  try {
    const response = await basicAxios.get(url, option);
    return response.data;
  } catch (err: unknown) {
    const { message } = err as Error;
    throw new Error(message);
  }
};

export const postApi = async (url: string, data: any, option?: any) => {
  try {
    const response = await basicAxios.post(url, data, option);
    return response.data;
  } catch (err: unknown) {
    const { message } = err as Error;
    throw new Error(message);
  }
};
