import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Input, Navbar } from '_/components';
import { PasswordValidation, RegisterValidation, colors } from '_/constants';
import { useApiAccountUpdate, useLoading, usePopup } from '_/hooks';
import { IAccountUpdateReq, IMutateStatus } from '_/types';

const AccountUpdate = () => {
  const navigate = useNavigate();
  const { openPopup } = usePopup();
  const { renderLoading } = useLoading();
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
  } = useForm<IAccountUpdateReq>();

  const [isPasswordSame, setIsPasswordSame] = useState<boolean>(false);
  const { status, mutate, isPending } = useApiAccountUpdate();

  useEffect(() => {
    renderLoading(isPending);
  }, [isPending]);

  useEffect(() => {
    const subscription = watch((inputObj) => {
      setIsPasswordSame(inputObj.password === inputObj.passwordCheck);
    });

    return () => subscription.unsubscribe();
  }, [watch]);

  useEffect(() => {
    handleAccountUpdate(status);
  }, [status]);

  const handleSubmitBtn: SubmitHandler<IAccountUpdateReq> = (data) => {
    mutate(data);
  };

  const handleAccountUpdate = (status: IMutateStatus) => {
    switch (status) {
      case 'success':
        openPopup('비밀번호가 변경되었습니다.', () => navigate('/points'));
        return;
      case 'error':
        openPopup('오류가 발생했습니다.');
        return;
    }
  };

  return (
    <>
      <Navbar title="비밀번호 변경" />
      <div className="layout">
        <StyledWrap>
          <StyledInner>
            <StyledTitleWrap>
              <StyledHeadLine>비밀번호를 변경해주세요</StyledHeadLine>
              <StyledText>
                초기 비밀번호는 보안에 취약합니다.
                <br />
                앞으로 사용하실 비밀번호를 입력해주세요.
              </StyledText>
            </StyledTitleWrap>
          </StyledInner>
          <StyledBottomForm onSubmit={handleSubmit(handleSubmitBtn)}>
            <StyledInputWrap>
              <Input
                placeholderText="비밀번호 입력"
                type="password"
                labelText="비밀번호 입력"
                isRequired={true}
                warningText={errors.password && errors.password.message}
                {...register('password', {
                  required: '비밀번호는 필수 입력입니다.',
                  pattern: PasswordValidation.password,
                })}
              />
              <Input
                placeholderText="비밀번호 확인"
                type="password"
                labelText="비밀번호 확인"
                isRequired={true}
                warningText={
                  !isPasswordSame
                    ? '비밀번호가 일치하지 않습니다.'
                    : errors.passwordCheck && errors.passwordCheck.message
                }
                {...register('passwordCheck', {
                  required: '비밀번호는 필수 입력입니다.',
                  pattern: PasswordValidation.password,
                })}
              />
              <Input
                placeholderText="지갑 비밀번호 입력"
                type="password"
                labelText="지갑 비밀번호 입력"
                isRequired={true}
                warningText={
                  errors.walletPassword
                    ? errors.walletPassword.message
                    : '*지갑 비밀번호는 숫자로 4자리이며, 암호화 코드로 저장되므로 당사는 보관하지 않아 분실시 찾기가 불가능합니다.'
                }
                {...register('walletPassword', {
                  required: '지갑 비밀번호는 필수 입력입니다.',
                  pattern: RegisterValidation.walletPassword,
                })}
              />
            </StyledInputWrap>
            <Button
              text="확인"
              type="submit"
              disabled={isPending || !isPasswordSame}
            />
          </StyledBottomForm>
        </StyledWrap>
      </div>
    </>
  );
};

export default AccountUpdate;

const StyledWrap = styled.div`
  margin: 90px 0 130px 0;
  display: flex;
  flex-direction: column;
  gap: 33px;
`;

const StyledInner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const StyledTitleWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 13px;
`;

const StyledHeadLine = styled.h1`
  color: ${colors.grey12};
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
`;

const StyledText = styled.p`
  color: ${colors.grey07};
  font-size: 13px;
  font-weight: 400px;
  line-height: 19px;
`;

const StyledBottomForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

const StyledInputWrap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
