import React from 'react';
import styled from 'styled-components';
import { Back } from '_/assets';
import { colors } from '_/constants';

interface INavbar {
  title: string;
}

const Navbar = ({ title }: INavbar) => {
  const handleClickBackBtn = () => {
    window.history.back();
  };

  return (
    <StyledWrap>
      <StyledNavWrap>
        <StyledBackButton onClick={handleClickBackBtn}>
          <Back />
        </StyledBackButton>
        <StyledTitle>{title}</StyledTitle>
      </StyledNavWrap>
    </StyledWrap>
  );
};

export default Navbar;

const StyledWrap = styled.div`
  position: fixed;
  top: 0;
  width: 100%;
`;

const StyledNavWrap = styled.nav`
  width: 100%;
  max-width: 575px;
  margin: 0 auto;
  height: 44px;
  display: flex;
  gap: 11px;
  padding: 10px 51px 10px 16px;
  box-sizing: border-box;
  align-items: center;
  background-color: ${colors.white};
`;

const StyledBackButton = styled.button`
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
`;

const StyledTitle = styled.h1`
  width: 272px;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  flex-grow: 1;
`;
