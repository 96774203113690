import React, { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { useSetRecoilState } from 'recoil';
import { GlobalPortal } from '_/GlobalPortal';
import { CheckBoxPopup, Loading, Popup } from '_/components';
import { useApiAuth, useLoading } from '_/hooks';
import {
  Main,
  AccountFind,
  SignUp,
  Points,
  Withdraw,
  LockUpList,
  Signature,
  DepositList,
  WithDrawList,
  AccountUpdate,
} from '_/pages';
import { userState } from '_/recoil';
import { IQueryStatus } from './types';

function App() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { renderLoading } = useLoading();
  const { data: authData, status, isLoading } = useApiAuth(pathname);
  const setUserInfo = useSetRecoilState(userState);

  useEffect(() => {
    renderLoading(isLoading);
  }, [isLoading]);

  useEffect(() => {
    handleAuthState(status);
  }, [status, authData]);

  const handleAuthState = (status: IQueryStatus) => {
    switch (status) {
      case 'success':
        setUserInfo(authData.data);
        return;
      case 'error':
        console.log(location);
        navigate('/');
    }
  };

  return (
    <div className="App">
      <GlobalPortal.Provider>
        <Popup />
        <CheckBoxPopup />
        <Loading />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/sign-up" element={<SignUp />} />
          <Route path="/account-find" element={<AccountFind />} />
          <Route path="/account-update" element={<AccountUpdate />} />
          <Route path="/points" element={<Points />} />
          <Route path="/points/withdraw/:ID" element={<Withdraw />} />
          <Route path="/lock-up-list" element={<LockUpList />} />
          <Route path="/signature" element={<Signature />} />
          <Route path="/deposit-list" element={<DepositList />} />
          <Route path="/withdraw-list" element={<WithDrawList />} />
          <Route path="*" element={<Navigate to={'/'} replace />} />
        </Routes>
      </GlobalPortal.Provider>
    </div>
  );
}

export default App;
